import React from "react";
import {useDispatch, useTrackedState} from "../store";
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

export default function Bracelet1({items,cat}) {
    const state = useTrackedState();
    const dispatch = useDispatch();


const productCLick = function(p_product){
    if(p_product.type==="cat"){dispatch({type: 'setCurrentCat', value: p_product})}
    if(p_product.type==="product"){dispatch({type: 'setCurrentProduct',value: p_product})}
    window.scrollTo(0, 0);
}




    return (
        <React.Fragment>
                 
        <div>
            <a  onClick={() => productCLick()}>
            {items.map(item=><p>{item.id}</p>)}
            </a>
            </div>


        </React.Fragment>
    )



}