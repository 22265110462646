import * as React from "react"
import SvgText from "../../tools/SvgText";

function SchemaVinylexlSVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={822.552}
            height={126.511}
            viewBox="0 0 822.552 126.511"
        >
            <g transform="translate(.007 -2.089)">
                <path
                    data-name="Ligne 12"
                    fill="none"
                    stroke="#d6d6d6"
                    d="M243.2 20.5h535"
                />
                <path
                    data-name="Ligne 13"
                    fill="none"
                    stroke="#d6d6d6"
                    d="M240.2 92.5h539"
                />
                <path
                    d="M706.4 38.5h-8.7l-437-.1c-12.1.6-5.7-14.3-15-17.4l-110.6-.6h-54c-4 0-19 0-22.5-.1H46.8a32.011 32.011 0 01-5.4-.3 6.719 6.719 0 01-3.7-3.7c-.8-2.5-.3-5.1-.3-7.7a8.888 8.888 0 00-.7-3.6c-.4-.8-1.6-2.4-2.6-2.4H4.5S.7 2.3.7 6.6v62.6a4.3 4.3 0 004 4.6h30c6.1 0-.5 15.9 6.9 17.8l202.5.4c10.8-1.5 3.1-15.7 13.8-17.6l93.6-.3 353.9.2v-.1h.9a2.925 2.925 0 003-2.9V41.5a2.865 2.865 0 00-2.9-3z"
                    fill={props.braceletCouleur}
                    stroke="#000"
                />
                <text
                    transform="translate(77.788 123.364)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"Zone imprimable"}
                    </tspan>
                </text>
                <text
                    transform="translate(169.488 123.364)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"65 x 20 mm"}
                    </tspan>
                </text>
                <path d="M56.3 111.1h16.8v17H56.3z" fill="none" stroke="#009fe3" />
                <path
                    d="M16.9 23.2a5.4 5.4 0 10-5.4-5.4 5.378 5.378 0 005.4 5.4"
                    fill="#fff"
                />
                <circle
                    cx={5.4}
                    cy={5.4}
                    r={5.4}
                    transform="translate(11.5 12.4)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path
                    d="M16.3 58.4a3.605 3.605 0 100-7.2 3.7 3.7 0 00-3.8 3.6 3.576 3.576 0 003.8 3.6"
                    fill="#fff"
                />
                <ellipse
                    cx={3.8}
                    cy={3.6}
                    rx={3.8}
                    ry={3.6}
                    transform="translate(12.5 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path
                    d="M.7 21.6C.9 21.4-.4 35.9 6 35.9h28.1c5.5 0 1.3-12.5 5.4-16.6"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="square"
                    strokeMiterlimit={2}
                />
                <path d="M283.3 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(278.4 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M318.9 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(314 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M355.5 61.2a5 5 0 10-4.9-5 5 5 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(350.6 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M427.9 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(423 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M393.3 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(388.4 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M463.9 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(459 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M499.9 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(495 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path
                    d="M535.8 61.2a5 5 0 10-4.9-5 5.062 5.062 0 004.9 5"
                    fill="#fff"
                />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(530.9 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M608.6 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(603.7 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path
                    d="M571.8 61.2a5 5 0 10-4.9-5 5.062 5.062 0 004.9 5"
                    fill="#fff"
                />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(566.9 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M643.9 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(639 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path d="M680.6 61.2a5 5 0 10-4.9-5 4.93 4.93 0 004.9 5" fill="#fff" />
                <ellipse
                    cx={4.9}
                    cy={5}
                    rx={4.9}
                    ry={5}
                    transform="translate(675.7 51.2)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={2}
                />
                <path
                    d="M51.4 28.4h184.3v56.7H51.4z"
                    fill="none"
                    stroke="#009fe3"
                    strokeWidth={1.005}
                />
                <SvgText

                    {...{
                        position: { x: 51, y: 28, width: 185, height: 57 },
                        lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                        textStyle: props.apercuStyle,
                        motifs: props.motifs,
                        data: {
                            text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                            textVertical: false,
                            textAlignH: props.apercuStyle.textAlign,
                            textAlignV: "center"
                        }
                    }}
                />
                <g data-name="Groupe 25">
                    <text
                        transform="translate(732.545 60.862)"
                        fill="#b7b7b7"
                        fontSize={12}
                        fontFamily="Helvetica"
                    >
                        <tspan x={0} y={0}>
                            {"12mm"}
                        </tspan>
                    </text>
                    <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                        <path d="M722.2 73.3v-34" strokeMiterlimit={10} />
                        <path data-name="Ligne 6" d="M719.2 39.5h6" />
                        <path data-name="Ligne 7" d="M719.2 73.5h6" />
                    </g>
                </g>
                <g data-name="Groupe 26">
                    <text
                        data-name="text3441"
                        transform="translate(788.545 59)"
                        fill="#b7b7b7"
                        fontSize={12}
                        fontFamily="Helvetica"
                    >
                        <tspan x={0} y={0}>
                            {"25mm"}
                        </tspan>
                    </text>
                    <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                        <path
                            data-name="path3443"
                            d="M778.2 91.11V20"
                            strokeMiterlimit={10}
                        />
                        <path data-name="Ligne 6" d="M775.2 20.2h6" />
                        <path data-name="Ligne 7" d="M775.2 91.31h6" />
                    </g>
                </g>
                <g data-name="Groupe 34" fill="none" stroke="#b7b7b7">
                    <path d="M51.58 106.1H235.7" strokeMiterlimit={10} />
                    <path data-name="Ligne 10" d="M235.5 102.5v7" />
                    <path data-name="Ligne 11" d="M51.88 102.5v7" />
                </g>
            </g>
        </svg>
    )
}

export default SchemaVinylexlSVG
