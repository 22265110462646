import React from 'react'
import {getDataValue, useTrackedState} from "../../store";
//import {GlobalContext} from "../../GlobalProvider";

const TypeColorSelectPopup = (props) => {

//    const global = useContext(GlobalContext);
    const state = useTrackedState();
//    const dispatch = useDispatch();




    function openClick() {

        document.querySelector( "#liste-"+props.item.id ).classList.add( 'open' );
    }


    const dataValue = getDataValue(state,props.item.id);

//    console.log(dataValue);
    const colorStyle = (dataValue)?dataValue.value:"";
    const label = (dataValue)?dataValue.label:"";
    return (
        <fieldset id={"param-"+props.item.id} onClick={openClick}><label>{props.item.label}</label>
            <div className={"container-fluid"}>
                <div className="row">
                    <div className="col">
                        <span className="couleur" style={{backgroundColor: colorStyle}}></span>
                        <span className="titre">{label}</span>
                    </div>
                </div>
            </div>
            <p></p>
        </fieldset>
    );
}

export default TypeColorSelectPopup;