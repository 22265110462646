import React from 'react'
//import {useTracked} from "../state";
import {getData, getDataValue, useTrackedState} from "../../store";
//import {GlobalContext} from "../../GlobalProvider";
import {formatString} from "../tools/Helper"
import SchemaVinyle16SVG from "./svg/SchemaVinyle16SVG"
import SchemaVinyle19SVG from "./svg/SchemaVinyle19SVG"
import SchemaVinyle19EmptySVG from "./svg/SchemaVinyle19EmptySVG"
import SchemaVinylexlSVG from "./svg/SchemaVinylexlSVG"

const SchemaBraceletVinyle = (props) => {

    //const global = useContext(GlobalContext);
    const state = useTrackedState();

    //const svgIcon = getDataValue(state,"motif-fichier").label);
    const motifFichier = getDataValue(state,"motif-fichier");
    const picto = (motifFichier&&motifFichier.typeFile&&motifFichier.typeFile==="picto")?process.env.PUBLIC_URL+"/sprites.svg#"+formatString(motifFichier.label):false;
    const file = (motifFichier&&motifFichier.typeFile&&motifFichier.typeFile==="custom_file")?motifFichier.preview:false;
    let  imgDisplayed = false;
    if(picto||file){
        imgDisplayed = (file)?{src:file,type:"file"}:{src:picto,type:"picto"};
    }
    const motifPosition = JSON.parse(JSON.stringify(getDataValue(state,"motif-position",false)));
    const motifPositionDisplayLeft = (imgDisplayed && motifPosition && motifPosition.includes("0"))?imgDisplayed:"";
    const motifPositionDisplayRight = (imgDisplayed && motifPosition && motifPosition.includes("1"))?imgDisplayed:"";

    const ligne1DataValue = getDataValue(state,"texte-ligne1");
    const ligne1 = (ligne1DataValue.value)?ligne1DataValue.value:(getData("texte-ligne1")?getData("texte-ligne1").placeHolder2:"");
    const ligne2DataValue = getDataValue(state,"texte-ligne2");
    const ligne2 = getDataValue(state,"texte-ligne2")?ligne2DataValue.value:"";





    let apercuStyle = {
        color: getDataValue(state,"bracelet-couleur").value,
        fill: getDataValue(state,"texte-couleur").value,
        fontFamily: getDataValue(state,"texte-font").value,
        fontSize: getDataValue(state,"taille-ecriture").value
    }


    switch (getDataValue(state,"texte-style").value){
        case "gras":
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="bold";
            break;
        case "italic":
            apercuStyle.fontStyle="italic";
            apercuStyle.fontWeight="normal";
            break;
        default:
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="normal";
            break;
    }


   // let align = 'left';

    switch (getDataValue(state,"texte-alignement").value){
        case "gauche":
            apercuStyle.textAlign="left";
            break;
        case "droite":
            apercuStyle.textAlign="right";
            break;
        default:
            apercuStyle.textAlign="center";
            break;
    }




    // todo : import dynamicly
    /*async function getSvgFile(){
        let importedSchema = []

        getData("taille").options.forEach(function(element, index){
            importedSchema[index] = await import(`../../svg/schemas/schema_vinyl_${element.value}.svg`);
        } )

        setSchema(importedSchema);
    }

    useEffect(async () => {
        console.log();

    }, []);*/






    let currentSchema= (<schemaVinyle16/>);
    const schemaType = (state.currentProduct.schemaType)?state.currentProduct.schemaType:getDataValue(state,"taille").value;

    switch (schemaType){
        case "16":
            currentSchema = (<SchemaVinyle16SVG
                texteLigne1={ligne1?ligne1:""}
                texteLigne2={ligne2?ligne2:""}
                braceletCouleur={getDataValue(state,"bracelet-couleur").value}
                motifs={{left:motifPositionDisplayLeft,right:motifPositionDisplayRight}}
                apercuStyle={apercuStyle}
            />);
            break;
        case "19":
            currentSchema = (<SchemaVinyle19SVG
                texteLigne1={ligne1?ligne1:""}
                texteLigne2={ligne2?ligne2:""}
                braceletCouleur={getDataValue(state,"bracelet-couleur").value}
                motifs={{left:motifPositionDisplayLeft,right:motifPositionDisplayRight}}
                apercuStyle={apercuStyle}
            />);
            break;
        case "19_empty":
            currentSchema = (<SchemaVinyle19EmptySVG
                braceletCouleur={getDataValue(state,"bracelet-couleur").value}
                motifs={{left:motifPositionDisplayLeft,right:motifPositionDisplayRight}}
                apercuStyle={apercuStyle}
            />);
            break;
        case "xl":
        case "25":
            currentSchema = (<SchemaVinylexlSVG
                texteLigne1={ligne1?ligne1:""}
                texteLigne2={ligne2?ligne2:""}
                braceletCouleur={getDataValue(state,"bracelet-couleur").value}
                motifs={{left:motifPositionDisplayLeft,right:motifPositionDisplayRight}}
                apercuStyle={apercuStyle}
            />);
            break;
        default:
            break;
    }



    return (

        <div className="perso__wrap-apercu schema-bracelet schema-bracelet-vinyle schema-svg">
            <div className="perso__apercu">
                <div className="perso__apercu_container">
                    <div id="apercu" className="apercu" >
                    {currentSchema}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchemaBraceletVinyle