import React, {useEffect} from 'react'
//import {useTracked} from "../state";
import {getData, getDataValue, useTrackedState} from "../../store";
//import {GlobalContext} from "../../GlobalProvider";
import {formatString} from "../tools/Helper";
import useResizeAware from "react-resize-aware";
import Header from "../Header";

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const SchemaRuban = (props) => {

    //const global = useContext(GlobalContext);
    const state = useTrackedState();

    // responsive
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    //const svgIcon = getDataValue(state,"motif-fichier").label);
    const motifFichier = getDataValue(state,"motif-fichier");
    const picto = (motifFichier&&motifFichier.typeFile&&motifFichier.typeFile==="picto")?process.env.PUBLIC_URL+"/sprites.svg#"+formatString(motifFichier.label):false;
    const file = (motifFichier&&motifFichier.typeFile&&motifFichier.typeFile==="custom_file")?motifFichier.preview:false;
    let  imgDisplayed = false;
    if(picto||file){
        imgDisplayed = (file)?{src:file,type:"file"}:{src:picto,type:"picto"};
    }
    const motifPosition = JSON.parse(JSON.stringify(getDataValue(state,"motif-position",false)));
    const motifPositionDisplayLeft = (imgDisplayed && motifPosition && motifPosition.includes("0"))?imgDisplayed:"";
    const motifPositionDisplayRight = (imgDisplayed && motifPosition && motifPosition.includes("1"))?imgDisplayed:"";

    const ligne1DataValue = getDataValue(state,"texte-ligne1");
    const ligne1 = (ligne1DataValue.value)?ligne1DataValue.value:(getData("texte-ligne1")?getData("texte-ligne1").placeHolder2:"");
    const ligne2DataValue = getDataValue(state,"texte-ligne2");
    const ligne2 = getDataValue(state,"texte-ligne2")?ligne2DataValue.value:"";

    let motifPositionDisplayLeftOutput = "";
    if(motifPositionDisplayLeft) {
        if (motifPositionDisplayLeft.type === "picto") {
            motifPositionDisplayLeftOutput = <svg>
                <use xlinkHref={motifPositionDisplayLeft.src}></use>
            </svg>
        } else {
            motifPositionDisplayLeftOutput = <img alt="" src={motifPositionDisplayLeft.src}/>
        }
    }

    let motifPositionDisplayRightOutput = "";
    if(motifPositionDisplayRight) {
        if (motifPositionDisplayRight.type === "picto") {
            motifPositionDisplayRightOutput = (<svg>
                <use xlinkHref={motifPositionDisplayRight.src}></use>
            </svg>)
        } else {
            motifPositionDisplayRightOutput = (<img alt="" src={motifPositionDisplayRight.src}/>)
        }
    }

    const rubanWidth = 40+parseInt(getDataValue(state,"ruban-largeur").value);
    let apercuStyle = {
        height : rubanWidth+"px",
        backgroundColor : getDataValue(state,"ruban-couleur").value,
        color : getDataValue(state,"texte-couleur").value,
        fill: getDataValue(state,"texte-couleur").value,
        fontFamily: getDataValue(state,"texte-font").value,
        fontSize:getDataValue(state,"taille-ecriture").value
    }
    if(dimensions.width<768){
        apercuStyle.left="calc( 50% - "+((rubanWidth/2)-5)+"px )";
    }

    React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    })

    switch (getDataValue(state,"texte-style").value){
        case "gras":
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="bold";
            break;
        case "italic":
            apercuStyle.fontStyle="italic";
            apercuStyle.fontWeight="normal";
            break;
        default:
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="normal";
            break;
    }

    let justifyContent = {justifyContent:"center"}
    //console.log(getDataValue(state,"texte-alignement").value);
    switch (getDataValue(state,"texte-alignement").value){
        case "gauche":
            apercuStyle.justifyContent="flex-start";
            break;
        case "droite":
            apercuStyle.justifyContent="flex-end";
            break;
        default:
            apercuStyle.justifyContent="center";
            break;
    }
    //console.log(motifPosition);
    //console.log(imgDisplayed);
    //console.log(motifPositionDisplayLeft);
    //console.log(motifPositionDisplayRight);
    return (
        <div className="perso__wrap-apercu schema-ruban" style={justifyContent}>
            <div className="perso__apercu">
                <div className="perso__apercu_container">
                    <div id="apercu" style={apercuStyle} className="apercu" >
                        <div className=" apercu__picto apercu__picto-gauche">
                            {motifPositionDisplayLeftOutput}
                        </div>
                        <div className="apercu__textes">
                            <div className="apercu__text1">{ligne1?ligne1:""}</div>
                            <div className="apercu__text2">{ligne2?ligne2:""}</div>
                        </div>
                        <div className=" apercu__picto apercu__picto-droit">
                            {motifPositionDisplayRightOutput}
                        </div>
                        <div className="apercu__largeur"><span>{getDataValue(state,"ruban-largeur").value}mm</span></div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SchemaRuban;