import { useDispatch, useTrackedState } from "../store";

import { scrollTo } from "./tools/Helper";
import React from "react";


function NavigationStep(props) {

    const state = useTrackedState();
    const dispatch = useDispatch();

    // test if currentProduct selected
    if (!state.currentProduct || state.currentProduct.type !== "product") { return '' }


    // select current step
    function selectStep(e) {
        const currentSelectedValue = e.currentTarget.getAttribute("data-to");

        dispatch({
            type: 'setCurrentStep',
            value: (state.currentProduct.content[currentSelectedValue] ?
                state.currentProduct.content[currentSelectedValue] : currentSelectedValue)
        })

        if (window.innerWidth < 768) {
            setTimeout(function() {
                const elmnt = document.querySelector(".open_current_step")
                var bodyRect = document.body.getBoundingClientRect(),
                    elemRect = elmnt.getBoundingClientRect(),
                    offset = elemRect.top - bodyRect.top;

                //     console.log(offset);
                //console.log(elmnt)
                scrollTo((document.documentElement), offset + 13, 600);
            }, 300)
        }

    }




    //    let isNotAvailable = false;






    const currentSelect = (state.currentStep && state.currentStep.id && state.currentStep.id === props.step.id) ? "current" : "";

    const notAvailableClass = (props.i < state.currentStepAvailable || state.currentStepAvailable === "ready") ? "" : "disabled";

    const customClassName = ("className" in props) ? props.className : "";
    return (         
    <span 
        key={props.i+"--"}
        className={"link-etape " + currentSelect+" "+notAvailableClass+" "+customClassName}
        onClick={selectStep}
        data-to={props.i}
    >
        {props.step.label}
    </span>
    );

}

export default NavigationStep;