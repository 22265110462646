import React from 'react'
//import {useTracked} from "../state";
import {getData, getDataValue, useTrackedState} from "../../store";
//import {GlobalContext} from "../../GlobalProvider";
import {formatString} from "../tools/Helper"
import SchemaBraceletSilicone12AdultSVG from "./svg/SchemaBraceletSilicone12AdultSVG";
import SchemaBraceletSilicone12JeuneSVG from "./svg/SchemaBraceletSilicone12JeuneSVG";
import SchemaBraceletSilicone12EnfantSVG from "./svg/SchemaBraceletSilicone12EnfantSVG";
import SchemaBraceletSilicone25SVG from "./svg/SchemaBraceletSilicone25SVG"
import SchemaBraceletSilicone20SVG from "./svg/SchemaBraceletSilicone20SVG"
import SchemaBraceletSiliconeClip12AdultSVG from "./svg/SchemaBraceletSiliconeClip12AdultSVG"
import SchemaBraceletSiliconeClip12JeuneSVG from "./svg/SchemaBraceletSiliconeClip12JeuneSVG"
import SchemaBraceletSiliconeClip12EnfantSVG from "./svg/SchemaBraceletSiliconeClip12EnfantSVG"

const SchemaBraceletSilicone = (props) => {

    //const global = useContext(GlobalContext);
    const state = useTrackedState();



    //const svgIcon = getDataValue(state,"motif-fiSchemaBraceletSiliconeClip112JeuneSVGchier").label);
    const motifFichier = getDataValue(state,"motif-fichier");
    const picto = (motifFichier&&motifFichier.typeFile&&motifFichier.typeFile==="picto")?process.env.PUBLIC_URL+"/sprites.svg#"+formatString(motifFichier.label):false;
    const file = (motifFichier&&motifFichier.typeFile&&motifFichier.typeFile==="custom_file")?motifFichier.preview:false;
    let  imgDisplayed = false;
    if(picto||file){
        imgDisplayed = (file)?{src:file,type:"file"}:{src:picto,type:"picto"};
    }
    const motifPosition = JSON.parse(JSON.stringify(getDataValue(state,"motif-position",false)));
    const motifPositionDisplayLeft = (imgDisplayed && motifPosition && motifPosition.includes("0"))?imgDisplayed:"";
    const motifPositionDisplayRight = (imgDisplayed && motifPosition && motifPosition.includes("1"))?imgDisplayed:"";
    const ligne1DataValue = getDataValue(state,"texte-ligne1");
    const ligne1 = (ligne1DataValue.value)?ligne1DataValue.value:(getData("texte-ligne1")?getData("texte-ligne1").placeHolder2:"");
    const ligne2DataValue = getDataValue(state,"texte-ligne2");
    const ligne2 = getDataValue(state,"texte-ligne2")?ligne2DataValue.value:"";



    let apercuStyle = {
     //   height: (40+parseInt(getDataValue(state,"ruban-largeur").value))+"px",
       // height: "100px",
        color: getDataValue(state,"texte-couleur").value,
        fill: getDataValue(state,"texte-couleur").value,
        fontFamily: getDataValue(state,"texte-font").value,
        fontSize: getDataValue(state,"taille-ecriture").value
    }


    switch (getDataValue(state,"texte-style").value){
        case "gras":
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="bold";
            break;
        case "italic":
            apercuStyle.fontStyle="italic";
            apercuStyle.fontWeight="normal";
            break;
        default:
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="normal";
            break;
    }



    switch (getDataValue(state,"texte-alignement").value){
        case "gauche":
            apercuStyle.textAlign="left";
            break;
        case "droite":
            apercuStyle.textAlign="right";
            break;
        default:
            apercuStyle.textAlign="center";
            break;
    }
    //console.log(getDataValue(state,"texte-alignement").value);
    switch (getDataValue(state,"texte-alignement").value){
        case "gauche":
            apercuStyle.justifyContent="flex-start";
            break;
        case "droite":
            apercuStyle.justifyContent="flex-end";
            break;
        default:
            apercuStyle.justifyContent="center";
            break;
    }








    switch (getDataValue(state,"texte-style").value){
        case "gras":
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="bold";
            break;
        case "italic":
            apercuStyle.fontStyle="italic";
            apercuStyle.fontWeight="normal";
            break;
        default:
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="normal";
            break;
    }

    let justifyContent = {justifyContent:"center"}
    //console.log(getDataValue(state,"texte-alignement").value);
    switch (getDataValue(state,"texte-alignement").value){
        case "gauche":
            apercuStyle.justifyContent="flex-start";
            break;
        case "droite":
            apercuStyle.justifyContent="flex-end";
            break;
        default:
            apercuStyle.justifyContent="center";
            break;
    }




    const schemaType = (state.currentProduct.schemaType)?state.currentProduct.schemaType:"";
    const schemaTaille = getDataValue(state,"taille").value;
    let currentSchema = false;

    const schemaArgs = {
        texteLigne1:ligne1?ligne1:"",
        texteLigne2:ligne2?ligne2:"",
        braceletCouleur:getDataValue(state,"bracelet-couleur").value,
        motifs:{left:motifPositionDisplayLeft,right:motifPositionDisplayRight},
        apercuStyle:apercuStyle
    }


        switch(schemaType){
            case "20mm":
                currentSchema = (<SchemaBraceletSilicone20SVG {...schemaArgs} />);
                break;
            case "25mm":
                currentSchema = (<SchemaBraceletSilicone25SVG {...schemaArgs} />);
                break;
            case "XL":
                switch(schemaTaille){
                    case "20":
                        currentSchema = (<SchemaBraceletSilicone20SVG {...schemaArgs} />);
                        break;
                    default:
                        currentSchema = (<SchemaBraceletSilicone25SVG {...schemaArgs} />);
                        break;
                }
                break;
            case "clip_12mm":
                switch(schemaTaille){
                    case "180":
                        currentSchema = (<SchemaBraceletSiliconeClip12JeuneSVG {...schemaArgs} />);
                        break;
                    case "160":
                        currentSchema = (<SchemaBraceletSiliconeClip12EnfantSVG {...schemaArgs} />);
                        break;
                    default:
                        currentSchema = (<SchemaBraceletSiliconeClip12AdultSVG {...schemaArgs} />);
                        break;
                }
                break;
            default:
                switch(schemaTaille){
                    case "180":
                        currentSchema = (<SchemaBraceletSilicone12JeuneSVG {...schemaArgs} />);
                        break;
                    case "160":
                        currentSchema = (<SchemaBraceletSilicone12EnfantSVG {...schemaArgs} />);
                        break;
                    default:
                        currentSchema = (<SchemaBraceletSilicone12AdultSVG {...schemaArgs} />);
                        break;
                }
                break;
        }

    return (

        <div className="perso__wrap-apercu schema-bracelet schema-bracelet-silicone schema-svg" style={justifyContent}>
            <div className="perso__apercu">
                <div className="perso__apercu_container">
                    <div id="apercu" className="apercu" >
                        {currentSchema}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchemaBraceletSilicone