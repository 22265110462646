import React from 'react'
import {getDataValue, useDispatch, useTrackedState} from "../../store";


const TypeSelectInline = (props) => {


    //const [state, dispatch] = useTracked();
    // get global contect
    //const global = useContext(GlobalContext);
    const state = useTrackedState();
    const dispatch = useDispatch();


  //  console.log(props.item.id);
    //get current value not formated ( can be the default value )
    let currentArrayValue = getDataValue(state,props.item.id,false);
    // if not value, set empty array

    currentArrayValue = (currentArrayValue===false)?[]:currentArrayValue;
    currentArrayValue = JSON.parse(JSON.stringify(currentArrayValue))
    currentArrayValue = (!Array.isArray(currentArrayValue))?[currentArrayValue]:currentArrayValue;

/*    console.log(props.item.id);
    console.log(currentArrayValue);*/
    // set item data to global
//    useEffect(() => {

 //   })


    // on select option
    function selectOption(e){
        // get attribute data-option ( the option index )
        const valueSelected = e.currentTarget.getAttribute("data-option");


        // if "multi-select-inline" format, multi item can be selected


        if(props.item.format==="multi-select-inline"){

            let position = -1;
            for (let i = 0; i < currentArrayValue.length; i++) {
                if(currentArrayValue[i]===valueSelected){
                    position = i;
                }
            }
            if ( ~position ){
                currentArrayValue.splice(position, 1);
            }
            else{
                currentArrayValue.push(valueSelected);
            }
        }else{
            currentArrayValue= valueSelected;
        }

      //  setDataValue(props.item.id,currentArrayValue);
        dispatch({
                type: 'setDataValue',
                key:props.item.id,
                value: currentArrayValue
            }
        )
    }


    // get data value
   // const dataValue = getDataValue(props.item.id);

  //  var FormatedDataValue = Array.isArray(dataValue)?dataValue:[dataValue];


 //   const dataInfo ="";




    const items = props.item.options.map((item, i) => {
        //let itemClass ="";
        //console.log(currentArrayValue);
        //console.log(JSON.parse(JSON.stringify(currentArrayValue)));
        /*currentArrayValue.forEach(function(item){
            console.log(i.toString()+"==="+item);
            if(i.toString() === item){
                console.log("ok");
                itemClass = "on";
            }
        });*/
        const itemClass = (currentArrayValue.includes(i.toString()))? "on":"" ;
        const itemInfo = (item.info)?<React.Fragment><br/><span>{item.info}</span></React.Fragment>:"";
        return (<div className={"col "+itemClass} key={props.id+'-'+i} data-option={i} onClick={selectOption}><span className={"content-label"}>{item.label}{itemInfo}</span></div>);
    });

    const displayLabel = (!props.item.displayLabel || props.item.displayLabel!=="notDisplay");
    const label = (displayLabel&&props.item.label)?(<label>{props.item.label}</label>):"";

    return (
    <fieldset id={"param-"+props.item.id} className={"select-inline"}>{label}
        <div className={"container-fluid"}>
            <div className={"row"}>
                {items}
            </div>
        </div>
        <p>{props.item.customInfo}</p>
    </fieldset>
    );
}

export default TypeSelectInline;