import * as React from "react"
import SvgText from "../../tools/SvgText";

function SchemaBraceletSilicone25SVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={624.095}
            height={71.45}
            viewBox="0 0 624.095 71.45"
        >
            <path
                d="M.25.8h566.4v70.4H.25z"
                fill={props.braceletCouleur}
                stroke="#000"
                strokeWidth={0.5}
            />
            <SvgText
                {...{
                    position: { x: 5.6693, y: 4, width: 501.7323, height: 65.1969 },
                    lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                    textStyle: props.apercuStyle,
                    motifs: props.motifs,
                    data: {
                        text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                        textVertical: false,
                        textAlignH: props.apercuStyle.textAlign,
                        textAlignV: "center"
                    }
                }}
            />
            <text
                transform="translate(590.095 38.3)"
                fill="#b7b7b7"
                fontSize={12}
                fontFamily="Helvetica"
            >
                <tspan x={0} y={0}>
                    {"25mm"}
                </tspan>
            </text>
            <g data-name="Groupe 16">

                <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                    <path d="M579.75 70.5V.3" strokeMiterlimit={10} />
                    <path data-name="Ligne 6" d="M576.75.5h6" />
                    <path data-name="Ligne 7" d="M576.75 70.5h6" />
                </g>
            </g>
        </svg>


    )
}

export default SchemaBraceletSilicone25SVG
