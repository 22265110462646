// independante popup list
import ListSelectPopup from "./fields/popups/ListSelectPopup";
import ListColorSelectPopup from "./fields/popups/ListColorSelectPopup";
import ListFontSelectPopup from "./fields/popups/ListFontSelectPopup";
import ListPictoSelectPopup from "./fields/popups/ListPictoSelectPopup";
import {getDataFromItem, useTrackedState} from "../store";


import React from "react";
import ListUploadPopup from "./fields/popups/ListUploadPopup";

export default function ListPopup({items}) {
    const state = useTrackedState();
    const nestedList = (items || []).map((v_item, i) => {
        const item = getDataFromItem(v_item);

        if (item.content) {
            return <ListPopup key={item.id +"_"+state.currentProduct+ "_listpopup_" + i} items={item.content}/>
        } else {
            switch (item.format) {
                case 'select-popup':
                    return <ListSelectPopup  key={item.id + "_listpopup_" + i} item={item}/>;
                case 'color-select-popup':
                    return <ListColorSelectPopup key={item.id + "_listpopup_" + i} item={item}/>;
                case 'font-select-popup':
                    return <ListFontSelectPopup key={item.id + "_listpopup_" + i} item={item}/>;
                case 'picto-select-popup':
                    return <ListPictoSelectPopup key={item.id + "_listpopup_" + i} item={item}/>;
                case 'upload-popup':
                    return <ListUploadPopup key={item.id + "_listpopup_" + i} item={item}/>;
                default:
                    return "";
            }
        }

    })



    return (
        <React.Fragment>
            {nestedList}
        </React.Fragment>

    )

}