import React, {useEffect, useState} from 'react';


import {loadJsonData, Provider,store,history} from './store';
import { ConnectedRouter } from "connected-react-router";
import './App.scss';
//import {Provider, useTracked} from './state';
//import {GlobalContext, GlobalProvider} from "./GlobalProvider"
import Perso from "./components/Perso";
import axios from "axios";
import { BrowserRouter, Route, Link } from "react-router-dom";
import bracelet1 from './components/Bracelet1'
function App() {

    const bootStrap =  (process.env.PUBLIC_URL === '')?(<link rel="stylesheet" href="/bootstrap.css" type="text/css" media="screen" />) :"";


    const [isLoaded,setIsLoaded] = useState(false)

    useEffect(() => {
        document.getElementById('root').classList.add("initialized")
        axios.get(process.env.PUBLIC_URL+'/configurator.json')
            .then(res => {
                loadJsonData(res.data);
                setIsLoaded(true);
            })


    },[])
//    const global = useContext(GlobalContext);



    /*function List(items) {

        const nestedList = (items || []).map((item, i) => {

            if (item.content) {
                return <List key={item.id + "_" + i} items={item.content}/>
            } else {
                console.log(item.type);
                switch (item.type) {
                    case 'select-popup':
                        return <ListSelectPopup item={item}/>;
                        break;
                    case 'color-select-popup':
                        return <ListColorSelectPopup item={item}/>;
                        break;
                    default:
                        return;
                }
            }

        })


        return (
            <React.Fragment>
                {nestedList}
            </React.Fragment>

        )

    }*/


    const content = (isLoaded)?<Perso />:"";
  return (
        <React.Fragment>
            {bootStrap}
            <Provider>
                    <BrowserRouter>
                        {content}
                        <Route path="/test/:id" component={bracelet1} />
                    </BrowserRouter>
            </Provider>
        </React.Fragment>

  );
}

export default App;
