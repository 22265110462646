import React from "react";

import ListProduct from "./ListProduct";


export default function ListCat({items,cat}) {


    const childrenCat = items.map((subproduct, i) => {
        if(subproduct.type!=="cat" ||  subproduct.content.length===0){
            return "";
        }else{
            return(<ListCat key={"list_cat_"+i} cat={subproduct} items={subproduct.content} />)
        }
    })
    return(
        <React.Fragment>
            <ListProduct cat={cat} items={items} />
            {childrenCat}
        </React.Fragment>
    )

}