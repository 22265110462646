import React from 'react'

import {useDispatch} from "../../../store";
import globalVar from "../../../GlobalVar";


const ListColorSelectPopup = (props) => {

    // use tracked system
    const dispatch = useDispatch();
    if(props.item.optionsRef){
        props.item.options=globalVar.configData.optionsRef[props.item.optionsRef];
    }

    const items = props.item.options.map((item, i) => {
        return (<span className={"lbtn"} key={props.item.id+'-'+i} style={{backgroundColor: item.value}} data-option={i} onClick={selectOption}><span>{item.label}</span></span>);
    });

    function selectOption(e){
        dispatch({
                type: 'setDataValue',
                key:props.item.id,
                value: e.target.getAttribute("data-option")
            }
        )
        closeClick()
    }

    function closeClick() {
        document.querySelector( "#liste-"+props.item.id ).classList.remove( 'open' );
    }


    /*
    dom.rubanLongueur.dataset.value = el.dataset.option
    dom.listeRubanLongueur.classList.remove( 'open' )
    Events.trigger( dom.rubanLongueur, 'change' )
    break;*/

    return (
        <div id={"liste-"+props.item.id} className={"liste liste-color"} >
            <div onClick={closeClick} className={"liste-bg"}></div>
            <div className="liste__container">
                <div className="liste__header">
                    <span className="liste__title">{props.item.label}</span>
                    <span className="liste__close lbtn" onClick={closeClick}></span>
                </div>
                <div className="liste__content container-fluid">
                    <div className="row">
                        {items}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListColorSelectPopup;