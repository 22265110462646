import * as React from "react"
import SvgText from "../../tools/SvgText";

function SchemaBraceletSilicone12AdultSVG(props) {


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={624.845}
            height={35}
            viewBox="0 0 624.845 35"
        >
            <path
                d="M.5.3h566.4v34H.5z"
                fill={props.braceletCouleur}
                stroke="#000"
                strokeWidth={0.5}
            />
            <path d="M566.4.8v33H.5V.8h565.9m.5-.5H0v34h566.9z" />
            <g data-name="Groupe 13">
                <text
                    transform="translate(590.845 21.862)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"12mm"}
                    </tspan>
                </text>
                <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                    <path d="M580.5 34.3V.3" strokeMiterlimit={10} />
                    <path data-name="Ligne 6" d="M577.5.5h6" />
                    <path data-name="Ligne 7" d="M577.5 34.5h6" />
                </g>
            </g>

            <SvgText

                {...{
                    position: { x: 0.5, y: 0, width: 566.4, height: 34 },
                    lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                    textStyle: props.apercuStyle,
                    motifs: props.motifs,
                    data: {
                        text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                        textVertical: false,
                        textAlignH: props.apercuStyle.textAlign,
                        textAlignV: "center"
                    }
                }}
            />
        </svg>


    )
}

export default SchemaBraceletSilicone12AdultSVG
