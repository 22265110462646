import OrderSummary from "./OrderSummary";
import TypeGroup from "./fields/TypeGroup";
import GroupNavigation from "./GroupNavigation"
//import {useSelector} from "react-redux";
import useResizeAware from 'react-resize-aware';
import {useDispatch, useSelector} from "../store";


import React, {useEffect, useRef, useState} from "react";


import SchemaRuban from "./schemas/SchemaRuban";
import SchemaBraceletSilicone from "./schemas/SchemaBraceletSilicone";
import SchemaBraceletVinyle from "./schemas/SchemaBraceletVinyle";
import SchemaBraceletTywek from "./schemas/SchemaBraceletTyvek";
import Header from "./Header";


function ProductConfigurator() {




    const state_currentProduct = useSelector(state => state.currentProduct);
   // const state_currentStep_id = useSelector(state => state.currentStep.id);
    const dispatch = useDispatch();
    const [resizeListener, sizes] = useResizeAware();
    const [headerDesktop, setHeaderDesktop] = useState("");
    const [headerMobile, setHeaderMobile] = useState("");
    const productConfRef = useRef();





    useEffect(() => {
        if(sizes && sizes.width){
            setHeaderDesktop((sizes.width>768)?(<Header />):"");
            setHeaderMobile((sizes.width<768)?(<Header />):"");
        }
    }, [sizes]);


    // test if currentProduct selected




    const reinitProduct = () =>{
        dispatch({type:"setCurrentProduct",value:state_currentProduct});
        dispatch({
                type: 'setCurrentStep',
                value: state_currentProduct.content[0]
            }
        )
    }



    // get param fieldset
    const items = state_currentProduct.content.map((item, i) => {
        return <TypeGroup key={i} index={i} item={item}/>;
    });


    let schema = false;
    //alert(state_currentProduct.schema)
    switch(state_currentProduct.schema){
        case "ruban":
            schema = <SchemaRuban/>;
            break;
        case "bracelet-silicone" :
            schema = <SchemaBraceletSilicone/>;
            break;
        case "bracelet-tyvek" :
            schema = <SchemaBraceletTywek/>;
            break;
        case "bracelet-vinyle" :
            schema = <SchemaBraceletVinyle/>;
            break;
        default:
            break;
    }


    useEffect( () => {
        dispatch({
                type: 'setCurrentStep',
                value: state_currentProduct.content[0]
            }
        )
        setTimeout(function(){

         productConfRef.current.classList.add("init-conf");
        },100)

    },[dispatch,state_currentProduct.content])

    return (
        <div className={"product-conf"} ref={productConfRef}>
            {resizeListener}
            {headerDesktop}
            {schema}
            <button className="perso__reset" onClick={reinitProduct}>
                <svg style={{width:"20px", height:"13px", top:"-3px"}} >
                    <use xlinkHref={process.env.PUBLIC_URL+"/sprites.svg#reinitialise"}></use>
                </svg>
                Réinitialiser
            </button>
            <div className={"perso__wrap-form"}>
                {headerMobile}
                {items}
                <OrderSummary/>
            </div>

            <div className="desktop-etapes d-none d-md-block">
                <GroupNavigation/>

            </div>


        </div>
    )
}

export default ProductConfigurator;