import * as React from "react"
import SvgText from "../../tools/SvgText";

function SchemaBraceletSiliconeClip12EnfantSVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={510.095}
            height={70.6}
            viewBox="0 0 510.095 70.6"
        >
            <g transform="translate(-.25 -19)">
                <path
                    d="M.5 19.3h452.7v34H.5z"
                    fill={props.braceletCouleur}
                    stroke="#000"
                    strokeWidth={0.5}
                />
                <path
                    d="M339.7 22.6V50H113.9V22.6h225.8m.5-.5H113.4v28.3h226.8z"
                    fill="#009fe3"
                />
                <circle
                    cx={8.5}
                    cy={8.5}
                    r={8.5}
                    transform="translate(10 27.8)"
                    fill="#fff"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <circle
                    cx={6.3}
                    cy={6.3}
                    r={6.3}
                    transform="translate(12.2 30)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <circle
                    cx={8.5}
                    cy={8.5}
                    r={8.5}
                    transform="translate(39.1 27.8)"
                    fill="#fff"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <circle
                    cx={6.3}
                    cy={6.3}
                    r={6.3}
                    transform="translate(41.3 30)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <circle
                    cx={8.5}
                    cy={8.5}
                    r={8.5}
                    transform="translate(424 27.8)"
                    fill="#fff"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <g data-name="Groupe 35">
                    <text
                        transform="translate(158.301 84.362)"
                        fill="#b7b7b7"
                        fontSize={12}
                        fontFamily="Helvetica"
                    >
                        <tspan x={0} y={0}>
                            {"Zone imprimable 80 x 10 mm"}
                        </tspan>
                    </text>
                    <path d="M134.3 72.1h16.8v17h-16.8z" fill="none" stroke="#009fe3" />
                </g>
                <g data-name="Groupe 27" fill="none" stroke="#b7b7b7">
                    <path d="M113.7 67.1h226.5" strokeMiterlimit={10} />
                    <path data-name="Ligne 10" d="M340 63.5v7" />
                    <path data-name="Ligne 11" d="M114 63.5v7" />
                </g>
                <g data-name="Groupe 18">
                    <text
                        transform="translate(476.345 40.862)"
                        fill="#b7b7b7"
                        fontSize={12}
                        fontFamily="Helvetica"
                    >
                        <tspan x={0} y={0}>
                            {"12mm"}
                        </tspan>
                    </text>
                    <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                        <path d="M466 53.3v-34" strokeMiterlimit={10} />
                        <path data-name="Ligne 6" d="M463 19.5h6" />
                        <path data-name="Ligne 7" d="M463 53.5h6" />
                    </g>
                </g>
            </g>
            <SvgText
                {...{
                    position: { x: 114, y: 2, width: 226, height: 28 },
                    lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                    textStyle: props.apercuStyle,
                    motifs: props.motifs,
                    data: {
                        text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                        textVertical: false,
                        textAlignH: props.apercuStyle.textAlign,
                        textAlignV: "center"
                    }
                }}
            />
        </svg>
    )
}

export default SchemaBraceletSiliconeClip12EnfantSVG
