import React, {useState} from "react";
import {useDispatch} from "../../store";

const FormFieldUpload = (props) =>{
    const [ufile,setUfile] = useState({file: false, uploading: false})
   // const state = useTrackedState();
    const dispatch = useDispatch();

    function handleChange(event){
        if(event.target.files[0]){

            const allowedFormat = ["image/svg","image/svg+xml","image/png","image/jpeg"]

            if(event.target.files[0].size > 2*1024*1024){
                alert("Le fichier est trop volumineux !");
                return false;
            };


            if(!allowedFormat.includes(event.target.files[0].type)){
                alert("Le Format du fichier doit étre ( png, jpg ou svg ) !");
                return false;
            }

            setUfile({
                uploading: false
            })

            const urlFile = URL.createObjectURL(event.target.files[0])
           // const imgFile = event.target.files[0];


            dispatch({
                    type: 'setDataValue',
                    key:props.item.id,
                    value: {
                        label:event.target.files[0].name,
                        value:event.target.files[0].name,
                        preview:urlFile,
                        typeFile:"custom_file",
                        targetFile:event.target.files[0]}
                }
            )

  props.callBack();


        }else{
            setUfile({uploading: true})
        }
    }

    return (
        <div className={"inputfile"} >
            <div className={(ufile.uploading)?"lds-dual-ring":""} ></div>
            <input type="file" name="fileUpload" id={"fileUpload"} onChange={handleChange}/>
            <label htmlFor="fileUpload">Utiliser votre <br/>propre motif</label>
            {/*<img alt="" src={ufile.file}/>*/}
        </div>
    );

}

export default FormFieldUpload;