import React, {Component} from "react";
import {isEqual, pick} from "lodash";

export default class SvgTspan extends Component {

    state = {
        x: 0,
        y: 0,
        bbox:{},
    };

    static keysForBBoxCheck = [
        "x",
        "y",
        "children",
        "textStyle",
        "lineArray"
    ];



    componentDidMount() {
        this.updateBBox();
    }

    componentDidUpdate(prevProps) {
        //console.log(this.props);
        const oldBBData = pick(prevProps, SvgTspan.keysForBBoxCheck);
        const currentBBData = pick(this.props, SvgTspan.keysForBBoxCheck);
        const oldBboxCheck = pick(this.state.bbox, ['width']);

        const bbox = (this.node)?this.node.getBBox():{};
        const bboxCheck = pick(bbox, ['width']);



        if (!isEqual(oldBBData, currentBBData)  ) {
            const self = this;

            setTimeout(function(){
                self.updateBBox();
            },10)

        }
    }

    updateBBox = () => {

        const self =this;

        if(self.node){
            const bbox = self.node.getBBox();



             //const bboxHeight = bbox.height - ( bbox.height * 0.3 ) ;
            const bboxHeight = this.props.textStyle.fontSize;

            const totalLineHeight = (bboxHeight * this.props.totalLine);

             let yPos = 0;

             let offset = bboxHeight * 0.2;
             /*if(this.props.heightBox > (bboxHeight * this.props.totalLine)){
                 yPos = ( this.props.heightBox / (2*this.props.totalLine) ) + ( (bboxHeight * this.props.lineIndex) );
             }else{*/
                 yPos = -((totalLineHeight - this.props.heightBox) / 2  ) + ( (bboxHeight * (this.props.lineIndex+1)) ) - offset ;
             //}

            yPos+=this.props.y;

            this.node.setAttribute("y",yPos);
            self.setState({
                bbox,
                x: this.props.x,
              //  y: this.props.y
                y:  yPos,
            });

        }

//- ( ( bboxHeight*(this.props.totalLine) / 2 ) / this.props.totalLine)

    };



    render() {
      //  console.log(this.props)

        //    console.log(rightPicto);
        return (

            <tspan
                style={this.props.textStyle}
                textAnchor={this.props.textAnchor}
                x={this.state.x}
                y={this.state.y}
                id={"text_span_"+this.props.lineIndex}
                ref={node => {
                    this.node = node;
                }}
            >

                {this.props.children}

            </tspan>

        );
    }
}