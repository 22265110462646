import React from "react";
import {useDispatch, useTrackedState} from "../store";
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import Bracelet1 from "./Bracelet1";

export default function ListProduct({items,cat}) {
    const state = useTrackedState();
    const dispatch = useDispatch();

    // on product product category click
    const productCLick = function(p_product){
        if(p_product.type==="cat"){dispatch({type: 'setCurrentCat', value: p_product})}
        if(p_product.type==="product"){dispatch({type: 'setCurrentProduct',value: p_product})}
        window.scrollTo(0, 0);
    }

    const isCurentProduct = (
        ( state.currentCat && state.currentCat.id === cat.id ) ||
        ( !state.currentCat && !cat.id )
    )?"current-product":"";

    const selectProduct = items.map((product, i) => {
        // check for separator
        let classSeparator = "";
        if( i>0 && items[i-1].format!==product.format){
            classSeparator="sep";
        }

        const productImageUrl = (product.img)?<img alt="" src={process.env.PUBLIC_URL+"/img/"+product.img}/>:"";
        return(

        
            <span className={"link-product "+classSeparator}  onClick={() => productCLick(product)} key={"product_1_"+i} >
                <div className={"product-image"}>{productImageUrl}</div>
                <div className={"product-title"}>
                    <span className={"product-format"}>{product.format}</span><br/>
                    <span className={"product-cat"}>{product.cat}</span>
                </div>
            </span>
        )
    })
    
    return (
        <React.Fragment>


            <div id={(cat)?cat.id:"products"} className={"product-listing "+isCurentProduct}>
                {selectProduct}

            </div>
            <Route
            path="/test"
            component={props => (
                <Bracelet1 
                    {...props}
                    cat={cat} 
                    items={items} 
                />
            )}
        /> 
        </React.Fragment>
    )



}