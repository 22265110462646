import {getProducts, useSelector,} from "../store"
import React from "react";
import ListPopup from "./ListPopup";
import ListCat from "./ListCat";
import Header from "./Header";
import ListProduct from "./ListProduct";


function ProductSelect() {

    //const state = useTrackedState();
    // const dispatch = useDispatch();

    const state_currentProduct_content = useSelector(state => state.currentProduct.content);
    //console.log(state_currentProduct_content)
    const productCat = getProducts();


    return (

        <React.Fragment>

            <div className={"product-cat-listing"}>
                <Header className={"d-block d-none d-md-block"}/>
                <ListCat cat={false} items={productCat}/>

            </div>
            <ListPopup items={state_currentProduct_content}/>

        </React.Fragment>
    )
}

export default ProductSelect;