import React from 'react'

import TypeColorSelectPopup from "./TypeColorSelectPopup";
import TypeSelectInline from "./TypeSelectInline";
import TypeSelectPopup from "./TypeSelectPopup";
import TypeText from "./TypeText";
import TypeQty from "./TypeQty";
import {checkCondition, getDataFromItem, getDataValue, useTrackedState} from "../../store";
import * as utils from "../../CustomParamFunction";
import NavigationStep from "../NavigationStep";


const TypeGroup = (props) => {


//    console.log("rendering type groupe")

    const state = useTrackedState();
    const level = (props.level)?props.level:0;
   // const [state, dispatch] = useTracked();
    //const global = useContext(GlobalContext);

    // loop on group content

    const items = props.item.content.map((v_item, i) => {

        // if it param item (field)
        const item = getDataFromItem(v_item);


        // test condition
        if(!checkCondition(item,state)){
            return false;
        };


        // custom modif on info
        //let currentArrayValue = getDataValue(state,item.id);

        const fieldValue = getDataValue(state,item.id);

        if(item.custom && item.custom.info){
            item.customInfo = utils[item.custom.info](item,fieldValue,state);
        }else{
            item.customInfo = item.info
        }




        if(item.type==="group"){
            return <TypeGroup key={i} item={item} level={level+1}/>;
        }else{
            switch (item.format) {

                case 'color-select-popup':
                    if(item.options.length>1){
                        return <TypeColorSelectPopup key={i} item={item}/>;
                    }else{
                        return false;
                    }

                case 'text':

                    return <TypeText key={i} item={item}/>;
                case 'select-inline':
                case 'multi-select-inline':
                    return (
                        <TypeSelectInline key={i} item={item}/>
                    );
                case 'qty':
                    return (
                        <TypeQty key={i} item={item}/>
                    );
                case 'select-popup':
                case 'font-select-popup':
                case 'picto-select-popup':
                //case 'upload-popup':
                    return (
                        <TypeSelectPopup key={i} item={item}/>
                    );
                default :
                    break;
            }
        }
        return  "";
    });

    let outputHtml = "";

    const testStepGroupFields = (state.currentStep && (typeof state.currentStep === "object") && state.currentStep.hasOwnProperty('id') && state.currentStep.id===props.item.id);
    if(level===0){
        outputHtml = (
            <section className={"type-group "+(testStepGroupFields?"open open_current_step":"") } data-etape={props.index}>
                <NavigationStep i={props.index} step={props.item} className={"d-md-none"}/>
                <div className={"section-content"}>
                    {items}
                </div>
            </section>
        
        )
    }else{
        const label = (<label>{props.item.label}</label>)
        outputHtml = (
            <div className={"sub-group "+(testStepGroupFields?"open":"") } data-etape={props.index}>
                {label}
                <div className={"sub-group-content"}>
                {items}
                </div>
                <p>{(props.item.customInfo)?props.item.customInfo:""}</p>
            </div>
        )
    }

    return (
        <React.Fragment>
        {outputHtml}

        </React.Fragment>
    );
}

export default TypeGroup;