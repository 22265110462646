import * as React from "react"


function SchemaVinyle19EmptySVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={766.345}
            height={114.3}
            viewBox="0 0 766.345 114.3"
        >
            <g transform="translate(0 .1)">
                <path
                    d="M7 .5A7.235 7.235 0 00.5 6.9v100.3a7.235 7.235 0 006.5 6.4h5.2l690.1.1c3.4-.4 6.4-2.5 6.9-6.1V66.1c-.5-3.6-3.4-5.7-6.8-6.1h-650c-2.5-.1-6.1-2-7.2-5.1v-48c-.4-3.2-5.1-6-8.9-6.5L7 .5z"
                    fill={props.braceletCouleur}
                    stroke="#000"
                />
                <path
                    d="M25.6 85.7a3.107 3.107 0 11-3.1-2.9 3.014 3.014 0 013.1 2.9"
                    fill="#fff"
                />
                <ellipse
                    cx={3.1}
                    cy={2.9}
                    rx={3.1}
                    ry={2.9}
                    transform="translate(19.5 83.8)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M29 16.4a6.668 6.668 0 01-6.8 6.4 6.6 6.6 0 01-6.8-6.4 6.668 6.668 0 016.8-6.4 6.538 6.538 0 016.8 6.4"
                    fill="#fff"
                />
                <ellipse
                    cx={6.8}
                    cy={6.4}
                    rx={6.8}
                    ry={6.4}
                    transform="translate(15.3 10)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M677.2 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6m-31.7 0a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(632.7 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M613.9 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(601 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M582.2 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(569.4 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M551.5 86.7a6.241 6.241 0 00-6.4-6 6.177 6.177 0 00-6.4 6 6.241 6.241 0 006.4 6 6.177 6.177 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(538.7 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M518.9 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(506 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M488.2 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(475.4 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M456.5 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(443.7 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M424.8 86.7a6.413 6.413 0 10-6.4 6 6.177 6.177 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(412 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M393.2 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(380.3 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(664.4 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <g data-name="Groupe 24">
                    <text
                        transform="translate(732.345 88.4)"
                        fill="#b7b7b7"
                        fontSize={12}
                        fontFamily="Helvetica"
                    >
                        <tspan x={0} y={0}>
                            {"19mm"}
                        </tspan>
                    </text>
                    <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                        <path d="M722 112.576V58.4" strokeMiterlimit={10} />
                        <path data-name="Ligne 6" d="M719 58.6h6" />
                        <path data-name="Ligne 7" d="M719 112.776h6" />
                    </g>
                </g>
            </g>
        </svg>

    )
}

export default SchemaVinyle19EmptySVG
