import {useReducer} from 'react';
import {createContainer} from 'react-tracked';

import GlobalVar from "./GlobalVar";

import deepmerge from 'deepmerge'
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";

import { createStore, applyMiddleware } from "redux";


const initialState = {
    currentCat: false,
    currentProduct: false,
    currentStep: false,
    currentStepAvailable: true,
    dataValue:{}
};

  

export const reducer = (state, action) => {

    switch (action.type) {
        case 'setCurrentCat':
            return {
                ...state,
                currentCat: action.value
            };
        case 'setCurrentProduct':
            if(action.value){

                setGroupedData(action.value);

                let currentStepAvailable = "ready";
                // check current setp and define if next step is available
                if(action.value.content){
                    action.value.content.forEach(function(v_step,p_stepIndex){
                        if(currentStepAvailable === "ready"){
                            v_step.content.forEach(function(v_field,p_fieldIndex){
                                // if groupe check first value ( need to improve but enough now )
                                const fieldIdToCheck = ( v_field.type==="group" )?v_field.content[0].id:v_field.id;
                               // console.log(fieldIdToCheck);
                                const storedValue = getDataValue(state,fieldIdToCheck,false);

                                if(getData(fieldIdToCheck).required && ( !storedValue ||storedValue ==="")){
     //                               console.log("required");
                                    currentStepAvailable = p_stepIndex+1;
                                }
                            })
                        }
                    })
                }

                //console.log(state);
                return {
                    ...state,
                    dataValue: {},
                    currentProduct: action.value,
                    currentStepAvailable: currentStepAvailable
                }
            }else{
                GlobalVar.data = {};
                return {
                    ...state,
                    currentProduct: false,
                    currentStep:false
                }
            }
        case 'setCurrentStep':
            return {
                ...state,
                currentStep: action.value
            };
        case 'setCurrentStepAvailable':
            return {
                ...state,
                currentStepAvailable: action.value
            };
        case 'setDataValue':


            let currentStepAvailable = "ready";
            // check current setp and define if next step is available
            if(state.currentProduct.content){
                state.currentProduct.content.forEach(function(v_step,p_stepIndex){
                    if(currentStepAvailable==="ready"){
                        v_step.content.forEach(function(v_field,p_fieldIndex){
                            // if groupe check first value ( need to improve but enough now )
                            const fieldIdToCheck = ( v_field.type==="group" )?v_field.content[0].id:v_field.id;
                            //     console.log(fieldIdToCheck);

                                const storedValue = (action.key!==fieldIdToCheck)?getDataValue(state,fieldIdToCheck,false):action.value;




                            if(getData(fieldIdToCheck).required && ( !storedValue ||storedValue ==="")){
      //                          console.log("required",v_field);
                                currentStepAvailable = p_stepIndex+1;
                            }
                        })
                    }
                })
            }



      //      console.log("======"+currentStepAvailable)
            return {
                ...state,
                dataValue: {
                    ...state.dataValue,
                    [action.key]: action.value
                },
                currentStepAvailable: currentStepAvailable
            };
        default:
            return state;
    }
};

export const history = createBrowserHistory();
export const store = createStore(

    connectRouter(history)(reducer),
  
    applyMiddleware(routerMiddleware(history))
  
  );

export function getDataFromItem(p_item){
    const item_id = (p_item.id)?p_item.id:p_item.fieldRef;
    const storedData = getData(item_id);
    return (storedData)?storedData:p_item;
}

//function getFieldFromItem()

// function setConfigData(p_data){
//     GlobalVar.configData = p_data;
// }

function setGroupedData(p_product){

    p_product.content.forEach(function(item){

        //let field;

        // if field ref ( get the field )
    /*    if(item.fieldRef){
            if(data.fieldsRef[item.fieldRef]){
                // get field id (if exist )
                field = data.fieldsRef[item.fieldRef]
                field.id = (item.id)?item.id:item.fieldRef;
            }else{
                console.log("error fieldRef "+item.fieldRef+" not found");
                return false;
            }
        }else{
            field = item
        }*/
       // console.log(field.id)

        // if group call
        if(item.type==="group"){

            setGroupedData(item);
        }else{
            // if ( param field )

            // get option if exist
         /*   if(field.optionsRef){
                if(data.optionsRef[field.optionsRef]){
                    field.options = field.options = data.optionsRef[field.optionsRef];
                }else{
                    console.log("error optionRef "+field.optionsRef+" not found");
                    return false;
                }
            }*/

            setData(item.id,item);
        }


    });
}

export const globalLoadFont = (item) =>{

    if(!GlobalVar.fonts[item.value]){
        const addFont = new FontFace(item.value, 'url('+process.env.PUBLIC_URL+"/"+item.file+')');
        addFont.load()
            .then(function (loaded_face) {document.fonts.add(loaded_face);})
            .catch(function (error) {console.log(error+" "+process.env.PUBLIC_URL+"/"+item.file);});
        GlobalVar.fonts[item.value]=addFont;
    }

}

// set data from json file
export const setData = (p_key, p_value) => {
    GlobalVar.data[p_key]=p_value;
    return null;
}

export const getProducts = () => {
    return GlobalVar.Products;
}

export const loadJsonData = (p_data) => {
    GlobalVar.configData = p_data;
//console.log(p_data)
    GlobalVar.Products=p_data.products.map(item => {
            return loadJsonNode(item);
        });
  //  console.log(GlobalVar.Products);
}


const loadJsonNode = (p_item) => {

        // if field ref ( get the field )
        if(p_item.fieldRef){
            if(GlobalVar.configData.fieldsRef[p_item.fieldRef]){
                // get field id (if exist )
    //            const cloneItem = { ...p_item };
                const field_id = (p_item.id!==undefined)?p_item.id:p_item.fieldRef;

           //     console.log(cloneItem);
           //     console.log(data.fieldsRef[p_item.fieldRef]);
                const combineMerge = (target, source, options) => {
                    const destination = target.slice()

                    source.forEach((item, index) => {
                        if (typeof destination[index] === 'undefined') {
                            destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
                        } else if (options.isMergeableObject(item)) {
                            destination[index] = deepmerge(item,target[index], options)
                        } else if (target.indexOf(item) === -1) {
                            destination.push(item)
                        }
                    })
                    return destination
                }
                p_item = deepmerge(p_item,GlobalVar.configData.fieldsRef[p_item.fieldRef],
                    { arrayMerge: combineMerge })
                p_item.id = (p_item.id)?(p_item.id):field_id;
              //  console.log(p_item);
            }else{
                console.log("error fieldRef "+p_item.fieldRef+" not found");
            //    return false;
            }
        }



        // if content children
        if( p_item.content && Array.isArray(p_item.content)){
            // get nested value

            p_item.content = p_item.content.map(item => {
                return loadJsonNode(item);
            });

        }

        // get option if exist
        if(p_item.optionsRef){
            if(GlobalVar.configData.optionsRef[p_item.optionsRef]){
                p_item.options = p_item.options = GlobalVar.configData.optionsRef[p_item.optionsRef];
            }else{
                console.log("error optionRef "+p_item.optionsRef+" not found");
             //   return false;
            }
        }


            return p_item;


}
// get data from current json data selected
export const getData=(p_key)=>{
    return GlobalVar.data[p_key]
};

export const getCurrentProductData=()=>{
    return GlobalVar.data;
}


export function checkCondition(item,state) {


    if( item["conditions"] ){

        const operator = item["conditions"]["operator"];
        let fieldValue = getDataValue(state,item["conditions"]["field"]).value;
        const expectedValue = item["conditions"]["value"];

        //little hack
        fieldValue = (fieldValue!==undefined)?fieldValue:"";
        switch(operator){
            case "eq":
                if(!(fieldValue===expectedValue)){return false;}
                break;
            case "neq":
                if(!(fieldValue!==expectedValue)){return false;}
                break;
            case "gt":
                if(!(fieldValue<expectedValue)){return false;}
                break;
            case "lt":
                if(!(fieldValue>expectedValue)){return false;}
                break;
            default:
                break;
        }
    }
    return true;
}

export const getAllDataValue = (state) => {
    let formatedData = {}
    Object.keys(getCurrentProductData()).forEach( (field ) =>{
        formatedData[field] = getDataValue(state,field);
    })
    return formatedData;
}

export const getDataValue=(state,p_key,p_formated=true, p_withDefault =true)=>{
    // test if it's option value

    if(GlobalVar.data[p_key]){



        const formatOptions = ["multi-select-inline","select-inline","select-popup", 'color-select-popup', 'font-select-popup']
        const formatMulti = ["multi-select-inline"];


        if(formatOptions.includes(GlobalVar.data[p_key]["format"])){
            var arrayReturn = getValueInOption(state,p_key,p_formated,p_withDefault);



            // if not empty
            if(arrayReturn){
                // if multi format return array
                if(formatMulti.includes(GlobalVar.data[p_key]["format"])) {
                    if(!Array.isArray(arrayReturn)){
                        if(!arrayReturn){
                            return [];
                        }else{
                            return [arrayReturn];
                        }
                    }
                    return arrayReturn;
                }
                return arrayReturn[0];
            }
            return false;
        }

    }


    // get value on value

    if(state.dataValue && (state.dataValue[p_key] || state.dataValue[p_key]==="") ){
        const dataValueArray = JSON.parse(JSON.stringify(state.dataValue[p_key]));

        if(p_formated){
            return (!dataValueArray.value)?{value:state.dataValue[p_key]}:state.dataValue[p_key];
        }else{
            return dataValueArray;
        }
    }else{
        //get default
        if(p_withDefault){
            if(GlobalVar.data[p_key] && ( GlobalVar.data[p_key]["defaultValue"] || GlobalVar.data[p_key]["defaultValue"] ==="" )){
                if(p_formated){
                    return (!GlobalVar.data[p_key]["defaultValue"].value)?{value:GlobalVar.data[p_key]["defaultValue"]}:GlobalVar.data[p_key]["defaultValue"];
                }else{
                    return GlobalVar.data[p_key]["defaultValue"];
                }

            }
        }
    }
    return false
};

// get value in option
const getValueInOption=(state,p_key,p_formated,p_withDefault)=>{

    let valueToReturn = [];

    // if value exist
    if( state.dataValue && state.dataValue[p_key] && GlobalVar.data[p_key] && GlobalVar.data[p_key]["options"]){

        const searchValue = Array.isArray(state.dataValue[p_key])?state.dataValue[p_key]:[state.dataValue[p_key]];
        if(p_formated) {
            searchValue.forEach(index_value => {
                valueToReturn.push(GlobalVar.data[p_key]["options"][index_value]);
            })
        }else{
            valueToReturn = state.dataValue[p_key];
        }

    }else{

        // else get default value
        if( GlobalVar.data[p_key] && GlobalVar.data[p_key]["defaultValue"] && p_withDefault ){

            if(GlobalVar.data[p_key]["options"]){
                const searchValue = Array.isArray(GlobalVar.data[p_key]["defaultValue"])?GlobalVar.data[p_key]["defaultValue"]:[GlobalVar.data[p_key]["defaultValue"]];
                searchValue.forEach(default_value=> {
                    GlobalVar.data[p_key]["options"].forEach((option, i) => {
                        // todo need to check if multi value
                        if(option.value===default_value){

                            if(p_formated) {
                                valueToReturn.push(option);
                            }else{
                                valueToReturn.push(i.toString());
                            }
                            // todo : exit loop
                        }
                    });
                })
            }
        }
    }

    return (valueToReturn.length)?valueToReturn:false;
}


//const { Provider, useTracked } = createContainer(() => useReducer(reducer, initialState));
export const {
    Provider,
    useTrackedState,
    useSelector,
    useUpdate: useDispatch,
} = createContainer(() => useReducer(reducer, initialState));