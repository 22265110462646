import {getDataValue} from "./store";

export function longeurRubanInfo(p_field,p_value){
    return (p_value && p_value.value) && p_field.info.replace('[value]',Math.round(p_value.value*3.03));
}

export function largeurRubanInfo(p_field,p_value,p_state){

    // todo make it more usefull with parms
    const depFieldValue = getDataValue(p_state,"ruban-largeur")

    return (depFieldValue.value===10) && p_field.info;
}