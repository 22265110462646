import React, {useRef,useState,useEffect} from 'react'

import {getDataValue, useDispatch, useTrackedState} from "../../store";
//import {GlobalContext} from "../../GlobalProvider";


const TypeQty = (props) => {
    // get global context
    const state = useTrackedState();
    const dispatch = useDispatch();

    // use for current qty
    const [qtyValue,setQtyValue] = useState(0)

    // set timeout, timing before edit and
    const timeout = useRef(false)

    // get data value
    const dataValue = getDataValue(state,props.item.id);


    useEffect( () => {
        const dataValue = getDataValue(state,props.item.id);
        setQtyValue(dataValue.value);
    },[props.item.id,state])

    /*useEffect( () => {
        const dataValue = getDataValue(state,props.item.id);
        setQtyValue(dataValue.value);
    },[qtyValue])*/


    const setProductQtyValue = (p_value) =>{

        // check item
        if( props.item.max && p_value > props.item.max) {
            dispatch({type: 'setDataValue',key:props.item.id,value: props.item.max})
            return props.item.max;
        }
        if(props.item.min && p_value <= props.item.min) {
            dispatch({type: 'setDataValue',key:props.item.id,value: props.item.min})
            return props.item.min;
        }

        const delta = p_value%props.item.multiple;

        if(p_value%props.item.multiple === 0){
            dispatch({type: 'setDataValue',key:props.item.id,value: p_value})
            return p_value;
        }else{
            dispatch({type: 'setDataValue',key:props.item.id,value: p_value - delta})
            return p_value - delta;
        }
    }

//console.log(dataValue);
    const changeValue = (e) => {


        //get event value
        const newValue = (parseInt(e.currentTarget.value))?parseInt(e.currentTarget.value):0;

        // set current state value
        setQtyValue(newValue)

        // clear timeout
        clearTimeout(timeout.current)

        // check quantity value in timeout
        timeout.current = setTimeout(function(){

            setQtyValue(setProductQtyValue(newValue) );

        },750)

    }


    const incrementItem = () => {
        setQtyValue(setProductQtyValue(dataValue.value+props.item.multiple) );
        /*if( props.item.max && dataValue.value > props.item.max) {
            dispatch({type: 'setDataValue',key:props.item.id,value: props.item.max})
        }else {
            dispatch({type: 'setDataValue',key:props.item.id,value: dataValue.value+props.item.multiple})
        }*/
    }

    const decreaseItem = () => {
        setQtyValue(setProductQtyValue(dataValue.value - props.item.multiple) );
        /*if(props.item.min && dataValue.value <= props.item.min) {
            dispatch({type: 'setDataValue',key:props.item.id,value: props.item.min})
        }else {
            dispatch({type: 'setDataValue',key:props.item.id,value: dataValue.value-props.item.multiple})
        }*/
    }


    const label = (props.item.label)?(<label>{props.item.label}</label>):"";
    return (
        <fieldset id={"param-"+props.item.id} className={"type-qty"}>
            {label}
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <button onClick={decreaseItem}>-</button>
                        <div className={"custom-input-number"}>
                            <input value={qtyValue} onChange={changeValue} onFocus={(event) => event.target.select()}/>
                        </div>

                        <button onClick={incrementItem}>+</button>
                    </div>
                </div>
            </div>
            <p>{props.item.customInfo}</p>
        </fieldset>
    );
}

export default TypeQty;