import React from 'react'

import { getData, getDataValue, globalLoadFont, useDispatch, useTrackedState, } from "../../../store";
import globalVar from "../../../GlobalVar";
//import {GlobalContext} from "../../GlobalProvider";

const ListSelectPopup = (props) => {

        //    const global = useContext(GlobalContext);
        const state = useTrackedState();
        const dispatch = useDispatch();

        //const dispatch = useUpdate();




        if (props.item.optionsRef) {
            props.item.options = globalVar.configData.optionsRef[props.item.optionsRef];
        }
        const listClass = (props.item.itemClass) ? " " + props.item.itemClass + " " : "";
        const optionClass = (props.item.optionClass) ? props.item.optionClass + "" : "";
        const ligne1DataValue = getDataValue(state, "texte-ligne1");
        const ligne1 = (ligne1DataValue.value) ? ligne1DataValue.value : (getData("texte-ligne1") ? getData("texte-ligne1").placeHolder2 : "");
        const items = props.item.options.map((item, i) => {


                globalLoadFont(item);



                //const dataValue = global.getDataValue(props.item.sampleFieldId);

                return ( < span className = { "lbtn " + optionClass } key = { props.item.id + '-' + i }
                    data-option = { i }
                    onClick = { selectOption } >
                    <span className = "titre" > { item.label } </span> <span className = "sample"
                    style = {
                        { fontFamily: item.label } } > { ligne1 } </span> </span>);
                });


            function selectOption(e) {
                //        global.setDataValue(props.item.id,e.target.getAttribute("data-option"))
                dispatch({
                    type: 'setDataValue',
                    key: props.item.id,
                    value: e.target.getAttribute("data-option")
                })
                closeClick()
            }


            function closeClick() {
                document.querySelector(".liste-" + props.item.id).classList.remove('open');
            }


            /*
            dom.rubanLongueur.dataset.value = el.dataset.option
            dom.listeRubanLongueur.classList.remove( 'open' )
            Events.trigger( dom.rubanLongueur, 'change' )
            break;*/

            return ( <div className = { "liste liste-" + props.item.id + listClass } >
                <div onClick = { closeClick } className = { "liste-bg" } > </div> 
                <div className = "liste__container" >
                <div className = "liste__header" >
                <span className = "liste__title" > { props.item.label } </span> 
                <span className = "lbtn liste__close" onClick = { closeClick } > </span> </div> <div className = "liste__content container-fluid">
                <div className = "row" > { items } </div> 
                </div> </div> </div>
            );
        }

        export default ListSelectPopup;