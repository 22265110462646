import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useTrackedState} from "../store";
import "moment/locale/fr";
import axios from "axios";
import {Transition } from "react-transition-group"

const Header = (props) => {
    const dispatch = useDispatch();
    const state = useTrackedState();
    const timeout = useRef(false)
    let isRendered = useRef(false);
 //   const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const [productDates, setProductDates]= useState({});

    const duration = 300;

    const transitionStyles = {
        entering: { opacity: 1 },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
    };

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
    }

    let currentTitle = "CHOISISSEZ VOTRE PRODUIT À PERSONNALISER";

    if (state.currentProduct) {
        currentTitle = (<React.Fragment><span>Je personnalise mon</span> {state.currentProduct.titre}</React.Fragment>);
    } else {
        if (state.currentCat) {
            currentTitle = "CHOISISSEZ VOTRE TYPE DE " + state.currentCat.titre + " À PERSONNALISER";
        }
    }


    useEffect(() =>{
        isRendered.current = true;
        if(state.currentProduct){

            // clear timeout
            clearTimeout(timeout.current)

            timeout.current = setTimeout(function() {
                axios({
                    method: 'post',
                    url: "/wp-json/api/perso-dates",
                    data: {delivery: state.currentProduct.delivery, shipment: state.currentProduct.shipment},
                    config: {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    }
                }).then(function (response) {
                    if (isRendered.current) {
                        setProductDates(JSON.parse(response.data));
                    }
                    return null;
                }).catch(function (error) {
                    console.log(error);
                })
            },100);
            return () => {
                isRendered.current = false;
            };
        }else{
            setProductDates({});
        }

    },[state.currentProduct])


    /*moment.updateLocale('fr', {
        workingWeekdays: [1, 2, 3, 4, 5, 6]
    });

    let shipmentDate = false;
    let deliveryDate = false;
    if (state.currentProduct.shipment && state.currentProduct.delivery){
        shipmentDate = moment().businessAdd(parseInt(state.currentProduct.shipment)).format('LL');
        deliveryDate = moment().businessAdd(parseInt(state.currentProduct.delivery)).format('LL');
    }*/

    const outputShippingDate = (productDates.shipment&&productDates.delivery)?
        <React.Fragment>Expédition le <strong>{productDates.shipment}</strong> - Livraison estimée le <strong>{productDates.delivery}</strong></React.Fragment>:"";


    const BackBtn= () => {

        const goBack = () => {
            if(state.currentProduct){
                dispatch({type: 'setCurrentProduct', value: ''})
                return "";
            }
            if(state.currentCat){
                dispatch({type: 'setCurrentCat', value: ''})
                return "";
            }

        }


        if(state.currentProduct || state.currentCat){

            return <div href={"#"} className={"btn go-back"} onClick={goBack}>Retour</div>
        }else{
            return "";
        }

    }



    const customClassName = (props.className)?props.className:"";
        return (

            <React.Fragment>
                <BackBtn/>
                <div className={"perso-header "+customClassName}>
                    <div className="perso-titre d-md-block">
                        {currentTitle}
                        <Transition transitionName="test" in={(Object.entries(productDates).length !== 0)} timeout={duration}>
                            {state => (
                            <div className={"output-shipping-date"} style={{...defaultStyle,...transitionStyles[state]}} key="transition-group-content" >
                                {outputShippingDate}
                            </div>
                                )}
                        </Transition>
                    </div>
                </div>
            </React.Fragment>
    )
};

export default Header;