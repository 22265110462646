import * as React from "react"
import SvgText from "../../tools/SvgText";

function SchemaVinyle19SVG(props) {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={766.345}
            height={150.2}
            viewBox="0 0 766.345 150.2"
        >
            <g transform="translate(0 .1)">
                <path
                    d="M7 .5A7.235 7.235 0 00.5 6.9v100.3a7.235 7.235 0 006.5 6.4h5.2l690.1.1c3.4-.4 6.4-2.5 6.9-6.1V66.1c-.5-3.6-3.4-5.7-6.8-6.1h-650c-2.5-.1-6.1-2-7.2-5.1v-48c-.4-3.2-5.1-6-8.9-6.5L7 .5z"
                    fill={props.braceletCouleur}
                    stroke="#000"
                />
                <text
                    transform="translate(147.943 146.941)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"Zone imprimable"}
                    </tspan>
                </text>
                <text
                    transform="translate(239.643 146.941)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"105 x 16 mm"}
                    </tspan>
                </text>
                <path d="M117.2 132.6H134v17h-16.8z" fill="none" stroke="#009fe3" />
                <path
                    d="M25.6 85.7a3.107 3.107 0 11-3.1-2.9 3.014 3.014 0 013.1 2.9"
                    fill="#fff"
                />
                <ellipse
                    cx={3.1}
                    cy={2.9}
                    rx={3.1}
                    ry={2.9}
                    transform="translate(19.5 83.8)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M29 16.4a6.668 6.668 0 01-6.8 6.4 6.6 6.6 0 01-6.8-6.4 6.668 6.668 0 016.8-6.4 6.538 6.538 0 016.8 6.4"
                    fill="#fff"
                />
                <ellipse
                    cx={6.8}
                    cy={6.4}
                    rx={6.8}
                    ry={6.4}
                    transform="translate(15.3 10)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M63.3 64h297.6v45.4H63.3z"
                    fill="none"
                    stroke="#009fe3"
                    strokeWidth={1.008}
                />
                <path
                    d="M677.2 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6m-31.7 0a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(632.7 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M613.9 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(601 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M582.2 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(569.4 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M551.5 86.7a6.241 6.241 0 00-6.4-6 6.177 6.177 0 00-6.4 6 6.241 6.241 0 006.4 6 6.177 6.177 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(538.7 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M518.9 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(506 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M488.2 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(475.4 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M456.5 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(443.7 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M424.8 86.7a6.413 6.413 0 10-6.4 6 6.177 6.177 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(412 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <path
                    d="M393.2 86.7a6.413 6.413 0 10-6.4 6 6.241 6.241 0 006.4-6"
                    fill="#fff"
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(380.3 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <ellipse
                    cx={6.4}
                    cy={6}
                    rx={6.4}
                    ry={6}
                    transform="translate(664.4 80.7)"
                    fill="none"
                    stroke="#000"
                    strokeWidth={0.48}
                />
                <SvgText

                    {...{
                        position: { x: 63, y: 64, width: 297, height: 45 },
                        lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                        textStyle: props.apercuStyle,
                        motifs: props.motifs,
                        data: {
                            text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                            textVertical: false,
                            textAlignH: props.apercuStyle.textAlign,
                            textAlignV: "center"
                        }
                    }}
                />
                <g data-name="Groupe 23">
                    <text
                        transform="translate(732.345 89.4)"
                        fill="#b7b7b7"
                        fontSize={12}
                        fontFamily="Helvetica"
                    >
                        <tspan x={0} y={0}>
                            {"19mm"}
                        </tspan>
                    </text>
                    <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                        <path d="M722 113.576V59.4" strokeMiterlimit={10} />
                        <path data-name="Ligne 6" d="M719 59.6h6" />
                        <path data-name="Ligne 7" d="M719 113.776h6" />
                    </g>
                </g>
                <g data-name="Groupe 33" fill="none" stroke="#b7b7b7">
                    <path d="M63.38 127.5H360.9" strokeMiterlimit={10} />
                    <path data-name="Ligne 10" d="M360.7 123.9v7" />
                    <path data-name="Ligne 11" d="M63.68 123.9v7" />
                </g>
            </g>
        </svg>



    )
}

export default SchemaVinyle19SVG
