import React from 'react'
import {getDataValue, useTrackedState} from "../../store";

const TypeSelectPopup = (props) => {

    const state = useTrackedState();
  //  const dispatch = useDispatch();
    /*const [state, dispatch] = useTracked();

    dispatch({
        type: 'setData',
        key: props.item.id,
        value: props.item}
    )
    dispatch({
        type: 'setDataDefault',
        key: props.item.id,
        value: props.item}
    )
    dispatch({
        type: 'setDataValue',
        key: props.item.id,
        value: props.item}
    )*/

//    const global = useContext(GlobalContext);


    function openClick() {
       // console.log("#liste-"+props.item.id);
        document.querySelector( ".liste-"+props.item.id ).classList.add( 'open' );
    }


    const dataValue = getDataValue(state,props.item.id);

    const displayLabel = (!props.item.displayLabel || props.item.displayLabel!=="notDisplay");
    const label = (displayLabel&&props.item.label)?(<label>{props.item.label}</label>):"";

    //console.log(dataValue);
    return (
        <fieldset id={"param-"+props.item.id} onClick={openClick}>{label}
            <div className={"container-fluid"}>
                <div className="row">
                    <div className="col">{(dataValue && dataValue.label)?dataValue.label:props.item.noValueMessage}</div>
                </div>
            </div>
            <p>{props.item.customInfo}</p>
        </fieldset>
    );
}

export default TypeSelectPopup;