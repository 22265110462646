import React from 'react'
import SvgText from "../../tools/SvgText"

const SchemaVinyle16SVG = (props) => {


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={766.345}
            height={122.4}
            viewBox="0 0 766.345 122.4"
            fill={props.braceletCouleur}
        >
            <text
                transform="translate(138.863 117.251)"
                fill="#b7b7b7"
                fontSize={12}
                fontFamily="Helvetica"
            >
                <tspan x={0} y={0}>
                    {"Zone imprimable"}
                </tspan>
            </text>
            <text
                transform="translate(230.563 117.251)"
                fill="#b7b7b7"
                fontSize={12}
                fontFamily="Helvetica"
            >
                <tspan x={0} y={0}>
                    {"105 x 13 mm"}
                </tspan>
            </text>
            <path d="M114.5 104.9h16.8v17h-16.8z" fill="none" stroke="#009fe3" />
            <path
                d="M40.2 86.1h658.9a9.562 9.562 0 0010-8.9V49.4c0-2.7-2.5-8.5-10-8.5-1.4 0-566.3-.1-658.9-.1a5.406 5.406 0 01-5.3-5.5c0-2.3-.2-25.7-.2-28.9S33.9.5 30.2.5H5.9C2.9.5.5 3.4.5 7v69.6c0 7.2 2.4 9.6 5.4 9.6l34.3-.1z"
                fill={props.braceletCouleur}
                stroke="#000"
            />
            <circle
                cx={6.5}
                cy={6.5}
                r={6.5}
                transform="translate(10.9 12.7)"
                fill="#fff"
            />
            <circle
                cx={6.5}
                cy={6.5}
                r={6.5}
                transform="translate(10.9 12.7)"
                fill="none"
                stroke="#000"
            />
            <circle
                cx={4.3}
                cy={4.3}
                r={4.3}
                transform="translate(13.1 59.2)"
                fill="#fff"
            />
            <circle
                cx={4.3}
                cy={4.3}
                r={4.3}
                transform="translate(13.1 59.2)"
                fill="none"
                stroke="#000"
            />
            <path
                d="M56.7 45h297.6v36.9H56.7z"
                fill="none"
                stroke="#009fe3"
                strokeWidth={0.997}
            />
            <path
                d="M645.4 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(640.5 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M672.4 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(667.5 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M618.4 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(613.5 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M591.3 68.4a4.9 4.9 0 114.9-4.9 4.845 4.845 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(586.4 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M564.3 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(559.4 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M537.3 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(532.4 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M510.3 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(505.4 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M483.3 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(478.4 58.6)"
                fill="none"
                stroke="#000"
                strokeMiterlimit={10}
                strokeWidth={0.8}
            />
            <path
                d="M456.3 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(451.4 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M429.3 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(424.4 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <path
                d="M402.2 68.4a4.9 4.9 0 114.9-4.9 4.908 4.908 0 01-4.9 4.9"
                fill="#fff"
            />
            <circle
                cx={4.9}
                cy={4.9}
                r={4.9}
                transform="translate(397.3 58.6)"
                fill="none"
                stroke="#000"
                strokeWidth={0.8}
            />
            <SvgText
                {...{
                    position: { x: 56.693, y: 45, width: 297, height: 37 },
                    lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                    textStyle: props.apercuStyle,
                    motifs: props.motifs,
                    data: {
                        text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                        textVertical: false,
                        textAlignH: props.apercuStyle.textAlign,
                        textAlignV: "center"
                    }
                }}
            />
            <g data-name="Groupe 22">
                <text
                    transform="translate(732.345 66.5)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"16mm"}
                    </tspan>
                </text>
                <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                    <path d="M722 86.622V40.5" strokeMiterlimit={10} />
                    <path data-name="Ligne 6" d="M719 40.67h6" />
                    <path data-name="Ligne 7" d="M719 86.792h6" />
                </g>
            </g>
            <g data-name="Groupe 32" fill="none" stroke="#b7b7b7">
                <path d="M56.38 99.6H354.8" strokeMiterlimit={10} />
                <path data-name="Ligne 10" d="M354.6 96v7" />
                <path data-name="Ligne 11" d="M56.68 96v7" />
            </g>
        </svg>


        )
    }


export default SchemaVinyle16SVG