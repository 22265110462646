import * as React from "react"

function SchemaBraceletTyvekEmptySVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={781.6}
            height={88.965}
            viewBox="0 0 781.6 88.965"

        >
            <path d="M.835.835h722.83v53.86H.835z" fill="#fff" />
            <path d="M.835.835h80v53.86h-80z" fill="#fff" />
            <path
                d="M.835.835h722.83v53.86H.835z"
                fill={props.braceletCouleur}
                stroke="#414042"
                strokeWidth={1.67}
            />
            <path
                d="M.755 2.205v51.12"
                fill="none"
                stroke="#414042"
                strokeWidth={0.79}
                strokeDasharray="18.99 3.17"
            />
            <path
                d="M.835.705h722.83v53.86H.835z"
                fill="none"
                stroke="#414042"
                strokeWidth={1.06}
            />
            <path
                d="M56.965 10.895l-11.27 9.16 10.51 8.53-9.45 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M50.875 10.895l-11.28 9.16 10.51 8.53-9.44 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M44.775 10.895l-11.27 9.16 10.51 8.53-9.44 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M38.685 10.895l-11.27 9.16 10.51 8.53-9.44 7.68 10.96 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M32.595 10.895l-11.27 9.16 10.51 8.53-9.45 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M26.505 10.895l-11.27 9.16 10.51 8.53-9.45 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <text
                transform="translate(626.695 85.965)"
                fill="#b7b7b7"
                fontSize={12}
                fontFamily="Helvetica"
            >
                <tspan x={-42.697} y={0}>
                    {"Zone de collage"}
                </tspan>
            </text>
            <g data-name="Groupe 21">
                <text
                    transform="translate(747.6 30.965)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"19mm"}
                    </tspan>
                </text>
                <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                    <path d="M737.255 55.141V.965" strokeMiterlimit={10} />
                    <path data-name="Ligne 6" d="M734.255 1.165h6" />
                    <path data-name="Ligne 7" d="M734.255 55.341h6" />
                </g>
            </g>
            <g data-name="Groupe 31" fill="none" stroke="#b7b7b7">
                <path d="M522.635 68.065h200.82" strokeMiterlimit={10} />
                <path data-name="Ligne 10" d="M723.255 64.465v7" />
                <path data-name="Ligne 11" d="M522.935 64.465v7" />
            </g>
        </svg>
    )
}

export default SchemaBraceletTyvekEmptySVG