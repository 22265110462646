import * as React from "react"
import SvgText from "../../tools/SvgText";

function SchemaBraceletSiliconeClip12AdultSVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={624.095}
            height={70.6}
            viewBox="0 0 624.095 70.6"
        >
            <g transform="translate(-.25 -19)">
                <path
                    d="M.5 19.3h566.4v34H.5z"
                    fill={props.braceletCouleur}
                    stroke="#000"
                    strokeWidth={0.5}
                />
                <path
                    d="M424.7 22.6V50H142.2V22.6h282.5m.5-.5H141.7v28.3h283.5z"
                    fill="#009fe3"
                />
                <text
                    transform="translate(216.301 84.362)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"Zone imprimable 100 x 10 mm"}
                    </tspan>
                </text>
                <path d="M192.3 72.1h16.8v17h-16.8z" fill="none" stroke="#009fe3" />
                <path
                    d="M141.7 67.1h283.5"
                    fill="none"
                    stroke="#b7b7b7"
                    strokeMiterlimit={10}
                />
                <circle
                    cx={8.5}
                    cy={8.5}
                    r={8.5}
                    transform="translate(10 27.8)"
                    fill="#fff"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <circle
                    cx={6.3}
                    cy={6.3}
                    r={6.3}
                    transform="translate(12.2 30)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <circle
                    cx={8.5}
                    cy={8.5}
                    r={8.5}
                    transform="translate(39.1 27.8)"
                    fill="#fff"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <circle
                    cx={6.3}
                    cy={6.3}
                    r={6.3}
                    transform="translate(41.3 30)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <circle
                    cx={8.5}
                    cy={8.5}
                    r={8.5}
                    transform="translate(538 27.8)"
                    fill="#fff"
                    stroke="#000"
                    strokeMiterlimit={10}
                    strokeWidth={0.5}
                />
                <g data-name="Groupe 17">

                    <text
                        transform="translate(590.345 40.862)"
                        fill="#b7b7b7"
                        fontSize={12}
                        fontFamily="Helvetica"
                    >
                        <tspan x={0} y={0}>
                            {"12mm"}
                        </tspan>
                    </text>
                    <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                        <path d="M580 53.3v-34" strokeMiterlimit={10} />
                        <path data-name="Ligne 6" d="M577 19.5h6" />
                        <path data-name="Ligne 7" d="M577 53.5h6" />
                    </g>
                </g>
                <path
                    data-name="Ligne 8"
                    fill="none"
                    stroke="#b7b7b7"
                    d="M425 63.5v7"
                />
                <path
                    data-name="Ligne 9"
                    fill="none"
                    stroke="#b7b7b7"
                    d="M142 63.5v7"
                />
            </g>
            <SvgText
                {...{
                    position: { x: 142, y: 2, width: 282, height: 28 },
                    lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                    textStyle: props.apercuStyle,
                    motifs: props.motifs,
                    data: {
                        text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                        textVertical: false,
                        textAlignH: props.apercuStyle.textAlign,
                        textAlignV: "center"
                    }
                }}
            />
        </svg>
    )
}

export default SchemaBraceletSiliconeClip12AdultSVG
