import React from 'react';
import {checkCondition, getAllDataValue, getCurrentProductData, getDataValue, useTrackedState} from "../store";
import axios from 'axios'
import NavigationCart from "./NavigationCart";

const OrderSummary = () => {

    const state = useTrackedState();
    const currentFields = getCurrentProductData();



    const getFieldValue = (fieldValue) => {


        if(Array.isArray(fieldValue)){
            return fieldValue.map( (field ) =>{
                 return getFieldValue(field);
            }).join(", ")
        }else{
            return  (fieldValue.label)?fieldValue.label:fieldValue.value;
        }
    }

    let detail = [];

    Object.keys(currentFields).forEach( (field ) =>{
        const fieldValue = getDataValue(state,field);

        if(checkCondition(currentFields[field],state)){

            const label = (currentFields[field].label)?currentFields[field].label:currentFields[field].value;
            const value = getFieldValue(fieldValue);


            if(value){
                return (
                    detail.push(<li key={state.currentProduct.id+"--"+label}>{label} : {value}</li>)
                    

                )
            }

        }

    })

    const addToCart = () => {



        const formData = new FormData();




        const comment = document.querySelector( '.order-message textarea' ).value;
        const allData =getAllDataValue(state);



        let postData= {};
        Object.keys( allData ).forEach((k_field) =>{


            if(checkCondition(currentFields[k_field],state)) {
                if(allData[k_field] && allData[k_field]["typeFile"]){
                    formData.append("image_"+k_field,allData[k_field].targetFile);
                }

                const value = getFieldValue(allData[k_field]);
                if (value) {
                    postData[k_field]=allData[k_field];
                }
            }

        })

        formData.append("dataValue", JSON.stringify(postData) );
        formData.append("currentProduct", state.currentProduct.id);
        formData.append("comment", comment);

        document.getElementsByClassName("ajout-panier")[0].classList.add( 'disabled' )
    axios({
        method: 'post',
        url: "/wp-json/api/perso-add",
        data: formData,
        config: {
            headers: {
                "Content-type": "multipart/form-data",
            }
        }
    })
            .then( d => {
                // console.log( d )


                if( d.data && d.request.status === 500 ) {
                    document.getElementsByClassName("ajout-panier")[0].classList.remove( 'disabled' )
                } else {
                    if( d.data.cart_items ) {
                        window.scroll( {
                            top:0,
                            left:0,
                            behavior:'smooth'
                        } )
                        setTimeout( function() {
                            const panier = document.querySelector( 'header .panier' )
                            const panierSpan = panier.querySelector( 'span' )
                            const panierSvg = panier.querySelector( 'svg' )
                            panierSpan.innerHTML = d.data.cart_items
                            panierSvg.classList.add( 'hl-panier' )
                            setTimeout( function() {
                                panierSvg.classList.remove( 'hl-panier' )
                            }, 1200 )
                            document.location.href = panier.href
                        }, 400 )
                    } else {

                        document.getElementsByClassName("ajout-panier")[0].classList.remove( 'disabled' )
                    }
                }
            } )

    }
    const title =  "Vous souhaitez un "+state.currentProduct.titre+" personnalisé :";
    return (
        <section data-etape="4" className={"type-group "+(state.currentStep==="orderSummary"?"open":"") } >
            <NavigationCart className={"d-md-none"}/>
            <div className={"section-content order-summary"}>
                <fieldset>
                    <label className="resume d-block">
                        <div>{title}</div>

                        <ul className="text-left d-inline-block">
                            {detail}
                        </ul>

                    </label>
                    <div className={"order-message"}>
                        <label>Commentaire</label>
                        <textarea rows="5" cols="33"  />
                    </div>
                    <div className="container-fluid" style={{maxWidth:"250px"}}>
                        <div className="row">
                            <button className="col ajout-panier" onClick={addToCart}>Ajouter au panier</button>
                        </div>
                    </div>
                </fieldset>
            </div>
        </section>
    );
};

export default OrderSummary;
