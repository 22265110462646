import React from 'react';
import NavigationStep from "./NavigationStep";
import {getDataValue, useSelector} from "../store";
import NavigationCart from "./NavigationCart";

//display group navigation
// first level of product
const GroupNavigation = () => {
    //const state = useTrackedState();
 //   const dispatch = useDispatch();
    const state_currentProduct_content = useSelector(state => state.currentProduct.content);
   // const state_currentProduct_content1 = useSelector(state => state.currentProduct.content.find(element=>element.id === state.currentProduct.content.id));

  //  let etapePanierNumber =0;
    let nextIsNotAvailable = false;
    // on select step
   /* function selectStep(e) {
        const currentSelectedValue = e.target.getAttribute("data-to");
        dispatch({
                type: 'setCurrentStep',
                value: (state.currentProduct.content[currentSelectedValue]?
                    state.currentProduct.content[currentSelectedValue]:currentSelectedValue)
            }
        )
    }*/

    // init step class not availabled
    let notAvailableClass = "diabled";

    // get current steps
    let currentStepsNav = state_currentProduct_content.map((item, i) => {
        // set not


        const jsxOutput = (<NavigationStep key={"navigation-step-"+i} i={i} step={item} isAvailable={nextIsNotAvailable}/>);
        nextIsNotAvailable = false;

        // check current setp and define if next step is available
        item.content.forEach(function(v_field){
            // if groupe check first value ( need to improve but enough now )
            const fieldIdToCheck = ( v_field.type==="group" )?v_field.content[0].id:v_field.id;
            //     console.log(fieldIdToCheck);
            const storedValue = getDataValue(fieldIdToCheck,false);

            if( storedValue === "" || !storedValue || !storedValue.length) {
                nextIsNotAvailable = false;
            }
        })

  //      etapePanierNumber++
        //console.log("end check storedValue")
        return jsxOutput

    });

    currentStepsNav.push(<NavigationCart key={"navigation-step-last"} p_notAvailableClass={notAvailableClass} />)

    return currentStepsNav;
}

export default GroupNavigation;
