import * as React from "react"
import SvgText from "../../tools/SvgText";

function SchemaBraceletTyvekSVG(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={781.6}
            height={91.045}
            viewBox="0 0 781.6 91.045"
        >
            <text
                transform="translate(626.695 86.3)"
                fill="#b7b7b7"
                fontSize={12}
                fontFamily="Helvetica"
            >
                <tspan x={-42.697} y={0}>
                    {"Zone de collage"}
                </tspan>
            </text>
            <path d="M.835 1.3h722.83v53.86H.835z" fill="#fff" />
            <path d="M.835 1.17h80v53.86h-80z" fill="#fff" />
            <path
                d="M.835 1.17h722.83v53.86H.835z"
                fill="none"
                fill={props.braceletCouleur}
                strokeWidth={1.67}
            />
            <path
                d="M.755 2.54v51.12"
                fill="none"
                stroke="#414042"
                strokeWidth={0.79}
                strokeDasharray="18.99 3.17"
            />
            <path
                d="M.835 1.04h722.83V54.9H.835z"
                fill="none"
                stroke="#414042"
                strokeWidth={1.06}
            />
            <path
                d="M56.965 11.23l-11.27 9.16 10.51 8.53-9.45 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M50.875 11.23l-11.28 9.16 10.51 8.53-9.44 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M44.775 11.23l-11.27 9.16 10.51 8.53-9.44 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M38.685 11.23l-11.27 9.16 10.51 8.53-9.44 7.68 10.96 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M32.595 11.23l-11.27 9.16 10.51 8.53-9.45 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <path
                d="M26.505 11.23l-11.27 9.16 10.51 8.53-9.45 7.68 10.97 8.9"
                fill="none"
                stroke="#414042"
                strokeWidth={0.36}
            />
            <g data-name="Groupe 20">
                <text
                    transform="translate(747.6 30.3)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"19mm"}
                    </tspan>
                </text>
                <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                    <path d="M737.255 54.476V.3" strokeMiterlimit={10} />
                    <path data-name="Ligne 6" d="M734.255.5h6" />
                    <path data-name="Ligne 7" d="M734.255 54.676h6" />
                </g>
            </g>
            <g data-name="Groupe 29" fill="none" stroke="#b7b7b7">
                <path d="M96.955 68.4h424.88" strokeMiterlimit={10} />
                <path data-name="Ligne 10" d="M521.635 64.8v7" />
                <path data-name="Ligne 11" d="M97.255 64.8v7" />
            </g>
            <g data-name="Groupe 30" fill="none" stroke="#b7b7b7">
                <path
                    data-name="path17"
                    d="M521.955 68.4h201.71"
                    strokeMiterlimit={10}
                />
                <path data-name="Ligne 10" d="M723.465 64.8v7" />
                <path data-name="Ligne 11" d="M522.255 64.8v7" />
            </g>
            <g data-name="Groupe 36">
                <text
                    data-name="text12"
                    transform="translate(234.695 86.3)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"Zone imprimable 150 x 14 mm"}
                    </tspan>
                </text>
            </g>
            <path
                d="M96.635 5.7h425.2v45.33h-425.2zm111.26 67.81h16.83v17.01h-16.83V73.51z"
                fill="none"
                stroke="#00afec"
                strokeMiterlimit={10}
                strokeWidth={1.05}
            />
            <SvgText
                {...{
                    position: { x: 97, y: 6, width: 425, height: 44 },
                    lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                    textStyle: props.apercuStyle,
                    motifs: props.motifs,
                    data: {
                        text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                        textVertical: false,
                        textAlignH: props.apercuStyle.textAlign,
                        textAlignV: "center"
                    }
                }}
            />
        </svg>
    )
}

export default SchemaBraceletTyvekSVG