import React, {useEffect, useRef, useState} from 'react'
import {getDataValue, useDispatch, useTrackedState} from "../../store";
//import {GlobalContext} from "../../GlobalProvider";
import parse from "html-react-parser";


const TypeText = (props) => {
    //const [state, dispatch] = useTracked();
    // get global contect
    //const global = useContext(GlobalContext);
    const state = useTrackedState();
    const dispatch = useDispatch();
    const dataValue = getDataValue(state,props.item.id);
    // use for current text
  //  const [textValue,setTextValue] = useState(0)

    // set timeout, timing before edit and
    const timeout = useRef(false)

    const [displayValue, setDisplayValue] = useState((dataValue && dataValue.value)?dataValue.value:props.item.placeHolder2);
    // set item data to global




    // get data value



    //let typeValueTimeout = false;
    const changeValue = (e) => {


        //get event value
        const newValue = e.target.value;

        // set current state value
        setDisplayValue(newValue)

        // clear timeout
        clearTimeout(timeout.current)

        // check quantity value in timeout
        timeout.current = setTimeout(function(){

            dispatch({type: 'setDataValue',key:props.item.id,value: newValue})

        },100)


    }

    useEffect(()=>{

        const obj = JSON.parse(JSON.stringify(state.dataValue));

        if(Object.keys(obj).length === 0 && obj.constructor === Object){
            setDisplayValue(props.item.placeHolder2)
        }

    },[state.dataValue,props.item.placeHolder2])

    const focusinValue = (e) =>{
       /* clearTimeout(typeValueTimeout);
        typeValueTimeout = setTimeout(function () {*/

        if(e.currentTarget.value===props.item.placeHolder2) {
            setDisplayValue("")
            dispatch({type: 'setDataValue',key:props.item.id,value: ""})
        }
       // }, 100)

    }


    const focusoutValue = (e) =>{

            if(e.currentTarget.value===""){
                setDisplayValue(props.item.placeHolder2)
                dispatch({type: 'setDataValue',key:props.item.id,value: ""})
            }


    }

    var customInfo = (props.item.customInfo)?parse(props.item.customInfo):"";
    //console.log(customInfo)

    const displayLabel = (!props.item.displayLabel || props.item.displayLabel!=="notDisplay");
    const label = (displayLabel&&props.item.label)?(<label>{props.item.label}</label>):"";
    return (
        <fieldset id={"param-"+props.item.id} className={"type-text"}>
            {label}
            <div className={"container-fluid"}>
                <div className={"row"}>
                        <input className="col" type="text"
                               placeholder={props.item.placeHolder?props.item.placeHolder:""} onFocus={focusinValue} onBlur={focusoutValue} value={(displayValue)?displayValue:""} onChange={changeValue} maxLength="100" />

                </div>
            </div>
            <p>{customInfo}</p>
        </fieldset>
    );
}

export default TypeText;