import React from 'react'
//import {GlobalContext} from "../../GlobalProvider";
import FormFieldUpload from "../../tools/FormFieldUpload"


const ListUploadPopup = (props) => {

//    const global = useContext(GlobalContext);
//     const state = useTrackedState();
//     const dispatch = useDispatch();





    function closeClick() {
        document.querySelector( ".liste-"+props.item.id ).classList.remove( 'open' );
    }






    /*
    dom.rubanLongueur.dataset.value = el.dataset.option
    dom.listeRubanLongueur.classList.remove( 'open' )
    Events.trigger( dom.rubanLongueur, 'change' )
    break;*/

    return (
        <div className={"liste liste-"+props.item.id}>
            <div onClick={closeClick} className={"liste-bg"}></div>
            <div className="liste__container">
                <div className="liste__header">
                    <span className="liste__title">{props.item.label}</span>
                    <span className="lbtn liste__close" onClick={closeClick}></span>
                </div>
                <div className="liste__content">

                            <FormFieldUpload callBack={closeClick} item={props.item}/>

                            <p>Ajoutez votre propre image (2 Mo maximum) dans un format vectorisé (AI, EPS, PDF) ou en haute définition (JPG, PNG 300 dpi) à l’aide du formulaire ci-dessus.
                            Nous vous enverrons par mail une maquette avec la visualisation de votre fichier sur le bracelet.</p>
                            <p>
                            Votre fichier sera téléchargé sur notre serveur lors de l'ajout de votre bracelet au panierAjoutez votre propre image (2 Mo maximum) dans un format vectorisé (AI, EPS, PDF) ou en haute définition (JPG, PNG 300 dpi) à l’aide du formulaire ci-dessus.
                            Nous vous enverrons par mail une maquette avec la visualisation de votre fichier sur le bracelet.</p>
                            <p>
                                Votre fichier sera téléchargé sur notre serveur lors de l'ajout de votre bracelet au panier
                            </p>

                        </div>

                        <div>
                            <button data-option="Aucun motif"  className={"btn"}>Aucun motif</button>
                        </div>


            </div>
        </div>
    );
}

export default ListUploadPopup;