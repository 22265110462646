import {useSelector} from "../store";
import ProductSelect from "./ProductSelect";

import ProductConfigurator from "./ProductConfigurator";
import React from "react";
//import TypeGroup from "./fields/TypeGroup";



const Perso = (props) => {

  //  const dispatch = useDispatch();
    //const state = useTrackedState();
  //  console.log("test")
    //const [state, dispatch] = useTracked();
    //const global = useContext(GlobalContext);
    const state_currentCat = useSelector(state => state.currentCat);

    const state_currentProduct = useSelector(state => state.currentProduct);

    const persoClass = (state_currentProduct)?"is-current-product":"";

    const productConfigurator = ( (state_currentProduct || state_currentProduct.type!=="product") && state_currentProduct)?(<ProductConfigurator />):'';

    const is_cat_class = (state_currentCat)?"is-current-cat":"";
    return (
        <div className={"App perso "+persoClass+" "+is_cat_class}>
            <ProductSelect />
            {productConfigurator}
        </div>
    )
}


export default Perso;