import React from 'react'

import {useDispatch} from "../../../store";
import FormFieldUpload from "../../tools/FormFieldUpload";
import globalVar from "../../../GlobalVar";
//import {GlobalContext} from "../../GlobalProvider";

const ListPictoSelectPopup = (props) => {

//    const global = useContext(GlobalContext);
   // const state = useTrackedState();
    const dispatch = useDispatch();

    //const dispatch = useUpdate();


    function formatString(str) {
        var accents    = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
        var accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
        str = str.split('');
        var strLen = str.length;
        var i, x;
        for (i = 0; i < strLen; i++) {
            if ((x = accents.indexOf(str[i])) !== -1) {
                str[i] = accentsOut[x];
            }
        }
        return str.join('').split(' ').join('-');
    }


    if(props.item.optionsRef){
        props.item.options=globalVar.configData.optionsRef[props.item.optionsRef];
    }
    const listClass = (props.item.itemClass)?" "+props.item.itemClass+" ":"";
    //const optionClass = (props.item.optionClass)?props.item.optionClass+"":"";


    // list on pictos category
    const items = props.item.options.map((cat, i) => {

        // list pictos in cat
        const pictos = cat.listing.map((picto, j) => {
            return (
                <span className={"lbtn"} key={"cat_picto_"+i+"_"+j} data-option={picto} onClick={selectOption} ><svg><use xlinkHref={process.env.PUBLIC_URL+"/sprites.svg#"+formatString(picto)}></use></svg></span>
            )
        })

        // display category
        return (
            <React.Fragment key={"cat_picto_"+i} >
                <h2>{cat.label}</h2>
                <div>
                    {pictos}
                </div>
            </React.Fragment>
        );
    });


    function selectOption(e){
        //global.setDataValue(props.item.id,{label:e.currentTarget.getAttribute("data-option")});
        dispatch({
                type: 'setDataValue',
                key:props.item.id,
                value: {typeFile: "picto",label:e.currentTarget.getAttribute("data-option"),value:e.currentTarget.getAttribute("data-option")}
            }
        )
        closeClick()
    }

    function selectNone(e){
        //global.setDataValue(props.item.id,{label:e.currentTarget.getAttribute("data-option")});
        document.getElementById("fileUpload").value="";
        dispatch({
                type: 'setDataValue',
                key:props.item.id,
                value: false
            }
        )
        closeClick()
    }


    function closeClick() {
        document.querySelector( ".liste-"+props.item.id ).classList.remove( 'open' );
    }

    /*
    dom.rubanLongueur.dataset.value = el.dataset.option
    dom.listeRubanLongueur.classList.remove( 'open' )
    Events.trigger( dom.rubanLongueur, 'change' )
    break;*/

    return (
        <div className={"liste liste-"+props.item.id+listClass}>
            <div onClick={closeClick} className={"liste-bg"}></div>
            <div className="liste__container">
                <div className="liste__header">
                    <span className="liste__title">{props.item.label}</span>
                    <span className="lbtn liste__close" onClick={closeClick}></span>
                </div>
                <div className="liste__content container-fluid">
                    <div className="picto-special-choice">
                        <div>
                            <button data-option={false} onClick={selectNone} className={"btn"}>Ne pas utiliser <br/>de motif</button>
                        </div>
                        <div>
                            <FormFieldUpload callBack={closeClick} item={props.item}/>
                            <div className={"toolTips"}>
                                <p>Taille : (2 Mo maximum) <br/>Format : (jpg, png ou svg)</p>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className={"choise-liste-title"}>Ou utiliser un motif dans la liste suivante</div>
                    {items}
                </div>
            </div>
        </div>
    );
}

export default ListPictoSelectPopup;