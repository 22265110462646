import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {getAllDataValue, useDispatch, useTrackedState} from "../store";
import NumberFormat from "react-number-format"

export default function NavigationCart(props){
    const state = useTrackedState();
    const dispatch = useDispatch();

    const timeout = useRef(false)
    const [prix,setPrix] = useState("---");

    // console.log("test");
    // on select step
    function selectStep(e) {
        const currentSelectedValue = "orderSummary"; //e.target.getAttribute("data-to");

        dispatch({
                type: 'setCurrentStep',
                value: (state.currentProduct.content[currentSelectedValue]?
                    state.currentProduct.content[currentSelectedValue]:currentSelectedValue)
            }
        )
    }

    useEffect(() => {

        // clear timeout
        clearTimeout(timeout.current)

        timeout.current = setTimeout(function(){

            axios({
                method: 'post',
                url: "/wp-json/api/perso-prix",
                data: {currentProduct:state.currentProduct.id,dataValue:getAllDataValue(state)},
                config: {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            }).then(function (response) {

                setPrix(JSON.parse(response.data).prix)
            }).catch(function (error) {
                console.log(error);
            })


        },500)




    },[state.dataValue,state])



    const currentSelect = (state.currentStep === "orderSummary" )?"current":"";
    const notAvailableClass = (state.currentStepAvailable!=="ready")?"disabled":"";
    const customClassName = ("className" in props)?props.className:"";

    const priceInfo = (prix!=="---")?(<NumberFormat displayType={"text"} fixedDecimalScale={true} decimalSeparator={","} decimalScale={2} value={prix} suffix={'€'} />):(<span>--</span>);
    return (<span className={"lbtn link-etape item-order-summary "+notAvailableClass+" "+currentSelect+" "+customClassName} data-to={"orderSummary"} onClick={selectStep}>
        {priceInfo}
        <svg id="panier" className={"blink"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.6 28.637" enableBackground="new 0 0 19.6 28.637" xmlSpace="preserve">
            <g>
                <rect y="6.633" width="19.6" height="22.003"/>
                <path d="M9.801,13.267c-3.658,0-6.635-2.976-6.635-6.633S6.143,0,9.801,0c3.657,0,6.633,2.976,6.633,6.633
                                S13.458,13.267,9.801,13.267z M9.801,1C6.693,1,4.166,3.527,4.166,6.633s2.527,5.633,5.635,5.633c3.105,0,5.633-2.527,5.633-5.633
                                S12.906,1,9.801,1z"/>
            </g>
        </svg>
    </span>);
}