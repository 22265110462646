import React from 'react'
//import {useTracked} from "../state";
import {getData, getDataValue, useTrackedState} from "../../store";
//import {GlobalContext} from "../../GlobalProvider";
import {formatString} from "../tools/Helper"
import SchemaBraceletTyvekSVG from "./svg/SchemaBraceletTyvekSVG"
import SchemaBraceletTyvekEmptySVG from "./svg/SchemaBraceletTyvekEmptySVG"

const SchemaBraceletTyvek = (props) => {

    //const global = useContext(GlobalContext);
    const state = useTrackedState();

    //const svgIcon = getDataValue(state,"motif-fichier").label);
    const motifFichier = getDataValue(state,"motif-fichier");
    const picto = (motifFichier&&motifFichier.typeFile&&motifFichier.typeFile==="picto")?process.env.PUBLIC_URL+"/sprites.svg#"+formatString(motifFichier.label):false;
    const file = (motifFichier&&motifFichier.typeFile&&motifFichier.typeFile==="custom_file")?motifFichier.preview:false;
    let  imgDisplayed = false;
    if(picto||file){
        imgDisplayed = (file)?{src:file,type:"file"}:{src:picto,type:"picto"};
    }
    const motifPosition = JSON.parse(JSON.stringify(getDataValue(state,"motif-position",false)));
    const motifPositionDisplayLeft = (imgDisplayed && motifPosition && motifPosition.includes("0"))?imgDisplayed:"";
    const motifPositionDisplayRight = (imgDisplayed && motifPosition && motifPosition.includes("1"))?imgDisplayed:"";
    const ligne1DataValue = getDataValue(state,"texte-ligne1");
    const ligne1 = (ligne1DataValue.value)?ligne1DataValue.value:(getData("texte-ligne1")?getData("texte-ligne1").placeHolder2:"");
    const ligne2DataValue = getDataValue(state,"texte-ligne2");
    const ligne2 = getDataValue(state,"texte-ligne2")?ligne2DataValue.value:"";

    let apercuStyle = {
        height: (40+parseInt(getDataValue(state,"ruban-largeur").value))+"px",
        color: getDataValue(state,"texte-couleur").value,
        fill: getDataValue(state,"texte-couleur").value,
        fontFamily: getDataValue(state,"texte-font").value,
        fontSize: getDataValue(state,"taille-ecriture").value
    }


    switch (getDataValue(state,"texte-style").value){
        case "gras":
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="bold";
            break;
        case "italic":
            apercuStyle.fontStyle="italic";
            apercuStyle.fontWeight="normal";
            break;
        default:
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="normal";
            break;
    }



    switch (getDataValue(state,"texte-alignement").value){
        case "gauche":
            apercuStyle.textAlign="left";
            break;
        case "droite":
            apercuStyle.textAlign="right";
            break;
        default:
            apercuStyle.textAlign="center";
            break;
    }
    //console.log(getDataValue(state,"texte-alignement").value);
    switch (getDataValue(state,"texte-alignement").value){
        case "gauche":
            apercuStyle.justifyContent="flex-start";
            break;
        case "droite":
            apercuStyle.justifyContent="flex-end";
            break;
        default:
            apercuStyle.justifyContent="center";
            break;
    }




    // todo : import dynamicly
    /*async function getSvgFile(){
        let importedSchema = []

        getData("taille").options.forEach(function(element, index){
            importedSchema[index] = await import(`../../svg/schemas/schema_vinyl_${element.value}.svg`);
        } )

        setSchema(importedSchema);
    }

    useEffect(async () => {
        console.log();

    }, []);*/




    switch (getDataValue(state,"texte-style").value){
        case "gras":
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="bold";
            break;
        case "italic":
            apercuStyle.fontStyle="italic";
            apercuStyle.fontWeight="normal";
            break;
        default:
            apercuStyle.fontStyle="normal";
            apercuStyle.fontWeight="normal";
            break;
    }

    let justifyContent = {justifyContent:"center"}
    //console.log(getDataValue(state,"texte-alignement").value);
    switch (getDataValue(state,"texte-alignement").value){
        case "gauche":
            apercuStyle.justifyContent="flex-start";
            break;
        case "droite":
            apercuStyle.justifyContent="flex-end";
            break;
        default:
            apercuStyle.justifyContent="center";
            break;
    }



    const schemaType = (state.currentProduct.schemaType)?state.currentProduct.schemaType:"";
    let currentSchema = false;
    switch(schemaType) {
        case "empty":
            currentSchema = (<SchemaBraceletTyvekEmptySVG
                braceletCouleur={getDataValue(state,"bracelet-couleur").value}
            />);
            break;
        default:
            currentSchema = (<SchemaBraceletTyvekSVG
                texteLigne1={ligne1?ligne1:""}
                texteLigne2={ligne2?ligne2:""}
                braceletCouleur={getDataValue(state,"bracelet-couleur").value}
                motifs={{left:motifPositionDisplayLeft,right:motifPositionDisplayRight}}
                apercuStyle={apercuStyle}
            />);
            break;
    }






    return (

        <div className="perso__wrap-apercu schema-bracelet schema-bracelet-tyvek schema-svg" style={justifyContent}>
            <div className="perso__apercu">
                <div className="perso__apercu_container">
                    <div id="apercu" className="apercu" >
                        {currentSchema}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchemaBraceletTyvek