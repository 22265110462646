import React, {Component} from "react";
import {isEqual, pick} from "lodash";
import SvgTspan from "./SvgTspan"

export default class SvgText extends Component {

    static textAligns = {
        left: x => x,
        center: (x, boxWidth, width, bboxx) => x - bboxx + ( (width - boxWidth) / 2) ,
        right: (x, boxWidth, width) => width - boxWidth + x
    };


    static textAlignsAnchor = {
        left: x => x,
        // center: (x, boxWidth, width, bboxx) => x - bboxx + ( (width - boxWidth) / 2) ,
        center: (x, width ) => x + ( width/ 2 ) ,
        right: (x, width ) => x + width
    };

    static textAnchor  = {
        left: x => "start",
        // center: (x, boxWidth, width, bboxx) => x - bboxx + ( (width - boxWidth) / 2) ,
        center: x => "middle" ,
        right: x => "end"
    };

   // console.log(this.props.position.y, bbox.height, this.props.position.height , bbox.y);
    static textAlignsV = {
        left: y => y,
        center: (y, boxHeight, height, bboxy) => y - bboxy + ( (  height - boxHeight ) / 2 ),
        right: (y, boxHeight, height) => (height - boxHeight) / 2 + y,
    };

    static pictoLeftAlign = {
        left: (xPos,bbox_width,pictoWidth,spacingText) => {
            return xPos-spacingText-pictoWidth
        },
        center: (xPos,bbox_width,pictoWidth,spacingText) => {
            return xPos-bbox_width/2-spacingText-pictoWidth
        },
        right: (xPos,bbox_width,pictoWidth,spacingText) => {
            return xPos-bbox_width-spacingText-pictoWidth
        }
    };
    static pictoRightAlign = {
        left: (xPos,bbox_width,pictoWidth,spacingText) => {
            return xPos+bbox_width+spacingText
        },
        center: (xPos,bbox_width,pictoWidth,spacingText) => {
            return xPos+bbox_width/2+spacingText
        },
        right: (xPos,bbox_width,pictoWidth,spacingText) => {
            return xPos+spacingText
        }
    };

    static keysForBBoxCheck = [
        "position.x",
        "position.y",
        "position.width",
        "position.height",
        "textStyle",
        "data.textAlignH",
        "data.textAlignV",
        "data.text"
    ];

    state = {
        x: 0,
        y: 0,
        textAnchor:"center",
        leftPicto_x:0,
        rightPicto_x:0
    };


    componentDidMount() {
        this.updateBBox();
    }

    componentDidUpdate(prevProps) {
        // console.log(SvgText);

        const oldBBData = pick(prevProps, SvgText.keysForBBoxCheck);
        const currentBBData = pick(this.props, SvgText.keysForBBoxCheck);


        if (!isEqual(oldBBData, currentBBData) || prevProps.motifs!== this.props.motifs) {
            const self=this;
         //   setTimeout(function(){
                self.updateBBox();
          //  },100)
        }
    }

    updateBBox = () => {


        /*
        console.log(textAlignH,SvgText.textAnchor[textAlignH](x));
      //  console.log(x,y,bbox)
        console.log(bbox);
        console.log(Math.round(SvgText.textAligns[textAlignV](y, bbox.height, height , bbox.y)));*/
        //console.log(this.props.y);
       // console.log(this.props.position.y, bbox.height, this.props.position.height , bbox.y);
        const self =this;
        const bbox = self.node.getBBox();

        const {
            position: { x, width },
            data: { textAlignV, textAlignH }
        } = self.props;

        let padding = 5;
        let motifHeight = this.props.position.height - ( padding * 2 );
        let pictoWidth = motifHeight;
        let spacingText = 5;

        // console.log(this.props.motifs);
        let WidthUpdateLeft= (this.props.motifs.left)?this.props.position.height - ( padding * 2 )+spacingText:0;
        let WidthUpdateRight= (this.props.motifs.right)?this.props.position.height - ( padding * 2 )+spacingText:0;

        let newWidthBox = width-WidthUpdateLeft-WidthUpdateRight;


        let xPos = Math.round(SvgText.textAlignsAnchor[textAlignH](x,  newWidthBox))+WidthUpdateLeft;
        let yPos =Math.round(SvgText.textAlignsV[textAlignV](self.props.position.y, bbox.height, self.props.position.height , bbox.y));

        self.setState({
            x: xPos,
            y: 0,
            leftPicto_x:SvgText.pictoLeftAlign[textAlignH](xPos,bbox.width,pictoWidth,spacingText),
            rightPicto_x:SvgText.pictoRightAlign[textAlignH](xPos,bbox.width,pictoWidth,spacingText),
            textAnchor:SvgText.textAnchor[textAlignH](x)

        });



    };

    renderDebugBBox = () => {
        const { position: { x, y, width, height }, debug } = this.props;
        const { bbox, x: x2, y: y2 } = this.state;

        if (!debug) {
            return null;
        }

        const style = {
            fill: "none",
            stroke: "#000",
            strokeWidth: 1
        };

        return [
            <rect
                shapeRendering="crispEdges"
                style={{ ...style, stroke: "#000" }}
                key="rect"
                x={x}
                y={y}
                width={width}
                height={height}
            />,
            bbox && (
                <rect
                    shapeRendering="crispEdges"
                    style={{ ...style, stroke: "#f00" }}
                    key="rectActualBB"
                    x={bbox.x + x2}
                    y={bbox.y + y2}
                    width={bbox.width}
                    height={bbox.height}
                />
            )
        ];
    };

    render() {

        const {
            textStyle: { bold, fontFamily, fontSize },
            lineStyle: { lineColor },
            data: { text, textVertical }
        } = this.props;

        const { x, y,textAnchor } = this.state;


        const style = {
            fontWeight: bold ? "bold" : "normal",
            fill: lineColor,
            fontFamily,
            fontSize
        };

        if (textVertical === true) {
            style.writingMode = "tb";
        }

       // console.log(this.props.motifs);
        let leftPicto =false
        let rightPicto =false
        let clipPath = false
        if(this.node){
            let padding = 5;
            let motifHeight = this.props.position.height - ( padding * 2 );
    /*        let pictoWidth = motifHeight;
            let spacingText = 5;
            let bbox = this.node.getBBox();*/
            //let leftXPosition = this.props.position.x+((this.props.position.width-bbox.width)/2)-spacingText-pictoWidth;

       //     let  leftXPosition = bbox.x-bbox.width-spacingText-pictoWidth;

        //    let rightXPosition = bbox.x+spacingText+pictoWidth
                //this.props.position.x+((this.props.position.width-bbox.width)/2)+bbox.width+spacingText;
            let YPosition = this.props.position.y+padding;
            //console.log(this.node)


            clipPath = (
                <React.Fragment>
                    <defs><clipPath id="overflowHidden">
                        <rect x={this.props.position.x}
                              y={this.props.position.y}
                              width={this.props.position.width}
                              height={this.props.position.height}></rect>
                    </clipPath></defs>
                </React.Fragment>
            )
            /**/
            /*           <rect
                                   shapeRendering="crispEdges"
                                   style={{fill: "none",stroke: "yellow",strokeWidth: 1}}
                                   key="rect"
                                   x={this.props.position.x}
                                   y={this.props.position.y}
                                   width={this.props.position.width}
                                   height={this.props.position.height}
                               />
                               <rect
                                   shapeRendering="crispEdges"
                                   style={{fill: "none",stroke: "red",strokeWidth: 1}}
                                   key="rectActualBB"
                                   x={bbox.x}
                                   y={bbox.y}
                                   width={bbox.width}
                                   height={bbox.height}
                               />*/

            if(this.props.motifs.left && this.props.motifs.left.type){
                if(this.props.motifs.left.type==="picto"){
                    leftPicto = <svg style={this.props.textStyle} clipPath="url(#overflowHidden)"><use x={this.state.leftPicto_x} y={YPosition} className={"motif-file"} xlinkHref={this.props.motifs.left.src} height={motifHeight+"px"} width={motifHeight+"px"}/></svg>
                }else{
                    leftPicto = <image clipPath="url(#overflowHidden)" x={this.state.leftPicto_x} y={YPosition} className={"motif-file"} xlinkHref={this.props.motifs.left.src} height={motifHeight+"px"}  width={motifHeight+"px"}/>
                }
            }


            if(this.props.motifs.right && this.props.motifs.right.type){
                if(this.props.motifs.right.type==="picto"){
                    rightPicto = <svg style={this.props.textStyle} clipPath="url(#overflowHidden)"><use  x={this.state.rightPicto_x} y={YPosition} className={"motif-file"} xlinkHref={this.props.motifs.right.src} height={motifHeight+"px"} width={motifHeight+"px"}/></svg>
                }else{
                    rightPicto = <image clipPath="url(#overflowHidden)" x={this.state.rightPicto_x} y={YPosition} className={"motif-file"} xlinkHref={this.props.motifs.right.src} height={motifHeight+"px"} width={motifHeight+"px"}/>
                }
            }
        }

        const lineArray = text.split("\\n")





        const textStyle = pick(this.props.textStyle,['justifyContent','textAlign']);
        const spanStyle = pick(this.props.textStyle,['color','fill','fontFamily','fontWeight','fontSize','fontStyle']);

    //    console.log(rightPicto);
        return (
            <React.Fragment>
                {leftPicto}

                {clipPath}
                {/*<rect*/}
                {/*    shapeRendering="crispEdges"*/}
                {/*    style={{fill: "none",stroke: "yellow",strokeWidth: 1}}*/}
                {/*    key="rect"*/}
                {/*    x={this.props.position.x}*/}
                {/*    y={this.props.position.y}*/}
                {/*    width={this.props.position.width}*/}
                {/*    height={this.props.position.height}*/}
                {/*/>*/}
                <text

                    key={"textHidden"}
                    ref={node => {
                        this.node = node;
                    }}
                    textAnchor={textAnchor}
                    style={{...textStyle,visibility: "hidden"}}
                    x={0}
                    y={0}
                >
                    {lineArray.map((line, idx) => (
                        <SvgTspan x={x} key={"line_array_1_"+idx} textStyle={spanStyle} heightBox={this.props.position.height} node={this.node} totalLine={lineArray.length} lineIndex={idx}>
                            {line}
                        </SvgTspan>
                    ))}
                </text>


                <text
                    key="text"
                    clipPath="url(#overflowHidden)"
                    textAnchor={textAnchor}
                    id={"text_svg_preview"}
                    style={{...textStyle}}
                    height={this.props.position.height}
                    x={0}
                    y={y}
                >
                    {lineArray.map((line, idx) => (
                        <SvgTspan x={x} key={"line_array_2_"+idx} lineArray={lineArray} textStyle={spanStyle}  textAnchor={textAnchor} y={this.props.position.y} heightBox={this.props.position.height} node={this.node} totalLine={lineArray.length} lineIndex={idx}>
                            {line}
                        </SvgTspan>
                    ))}
                </text>
                {rightPicto}
            </React.Fragment>
        );
    }
}