import * as React from "react"
import SvgText from "../../tools/SvgText";

function SchemaBraceletSilicone12JeuneSVG(props) {


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={567.095}
            height={35}
            viewBox="0 0 567.095 35"
        >
            <path
                d="M.25.3h509.5v34H.25z"
                fill={props.braceletCouleur}
                stroke="#000"
                strokeWidth={0.5}
            />

            <g data-name="Groupe 15">
                <text
                    transform="translate(533.095 21.862)"
                    fill="#b7b7b7"
                    fontSize={12}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"12mm"}
                    </tspan>
                </text>
                <g data-name="Groupe 12" fill="none" stroke="#b7b7b7">
                    <path d="M522.75 34.3V.3" strokeMiterlimit={10} />
                    <path data-name="Ligne 6" d="M519.75.5h6" />
                    <path data-name="Ligne 7" d="M519.75 34.5h6" />
                </g>
            </g>
            <SvgText

                {...{
                    position: { x: 0.5, y: 0, width: 509.5, height: 34 },
                    lineStyle: { lineColor: "#000000", lineWidth: 0, lineType: "none" },
                    textStyle: props.apercuStyle,
                    motifs: props.motifs,
                    data: {
                        text: props.texteLigne1+((props.texteLigne2)?'\\n'+props.texteLigne2:''),
                        textVertical: false,
                        textAlignH: props.apercuStyle.textAlign,
                        textAlignV: "center"
                    }
                }}
            />
        </svg>

    )
}

export default SchemaBraceletSilicone12JeuneSVG
